<template>
  <div>
    <ejs-dialog
      ref="roomReservationSearchPopup"
      header="예약조회"
      position="{ x: 'center', y: 'center' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      width="1600"
      height="800"
      @open="onPopupOpen"
      @close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">입실일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          v-model="arrivalDateRange"
                          type="lookup-condition"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">검색조건</div>
                    <ul class="content">
                      <li class="item" style="width: 400px;">
                        <input-text
                          ref="searchValue"
                          placeholder="예약자명, 투숙객명, 그룹명, 객실번호, 비고, 예약자 연락처 뒷 4자리"
                          v-model="searchOptions.searchValue"
                          @keydown.enter="onViewButtonClicked"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      v-on:click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약 목록</div>
                      <div class="header-caption">[{{ numberWithCommas(reservationsCount) }}건]</div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridProps"
                        :dataSource="reservations"
                        @headerCellInfo="onGridHeaderCellInfo"
                        @queryCellInfo="onGridQueryCellInfo"
                        @onGridDialogDoubleClickedOrEnterKeyed="onGridDialogDoubleClickedOrEnterKeyed"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseButtonClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import ErpButton from "@/components/button/ErpButton.vue";


import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import {
  getTodayDate,
  getAddDays,
} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import { numberWithCommas } from '@/utils/number';
import {orderBy as _orderBy} from "lodash";
import {commonCodesGetSortNo} from "@/utils/commonCodes";

export default {
  name: "RoomReservationSearchPopup",
  components: {
    InputDateRange,
    EjsGridWrapper,
    InputText,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      searchOptions: {
        arrivalDateFrom: null,
        arrivalDateTo: null,
        searchValue: null,
      },
      reservations: [],
      reservationsCount: 0,
    };
  },
  created() {},
  beforeDestroy() {},
  computed: {
    arrivalDateRange: {
      get() {
        return {
          from: this.searchOptions.arrivalDateFrom,
          to: this.searchOptions.arrivalDateTo,
        };
      },
      set(arrivalDateRange) {
        this.searchOptions.arrivalDateFrom = arrivalDateRange.from;
        this.searchOptions.arrivalDateTo = arrivalDateRange.to;
      },
    },
    gridProps() {
      return {
        provides: [Edit, Filter, ForeignKey, Page, Selection, Resize, ExcelExport],
        isInPopup: true,
        allowFiltering: false,
        allowSorting: false,
        allowPaging: true,
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        editSettings: {
          allowEditing: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: {pageSize: 50},
        columns: [
          {
            field: "stayId",
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_TYPE",
          },
          {
            field: "resveName",
            headerText: "예약자",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "grpName",
            headerText: "단체명",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "rresveStatus",
            headerText: "예약상태",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RRESVE_STATUS",
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "stayCnt",
            headerText: "박수",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "roomAmt",
            headerText: "객실료",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "resveRemarks",
            headerText: "예약비고",
            type: "string",
            minWidth: 16,
            width: 150,
          },
          {
            field: "frontMemo",
            headerText: "프론트메모",
            type: "string",
            minWidth: 16,
            width: 150,
          },
          {
            field: "golfResve",
            headerText: "골프예약",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "rresveNo",
            headerText: "예약번호",
            type: "string",
            minWidth: 16,
            width: 100,
          },
          {
            field: "roomNo",
            headerText: "객실번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "groupName",
            headerText: "그룹명",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "resveContactTel",
            headerText: "연락처",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
          },
          {
            field: "guestName",
            headerText: "투숙객",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "memberDiv",
            headerText: "회원구분",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_DIV",
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_GRADE",
          },
          {
            field: "roomSaleKind",
            headerText: "판매유형",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_SALE_KIND",
          },
          {
            field: "roomDcKind",
            headerText: "할인유형",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_DC_KIND",
          },
          {
            field: "vipFlag",
            headerText: "VIP",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "resveKind",
            headerText: "예약종류",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RESVE_KIND",
          },
          {
            field: "resveChannel",
            headerText: "예약채널",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RESVE_CHANNEL",
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
          },
          {
            field: "updateName",
            headerText: "수정자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
          },
        ],
      };
    },
  },
  async mounted() {},
  methods: {
    numberWithCommas,
    showPopup(args) {
      this.searchOptions.arrivalDateFrom = args.bsnDate || getTodayDate();
      this.searchOptions.arrivalDateTo = getAddDays(this.searchOptions.arrivalDateFrom, 90);
    },
    onPopupOpen() {
      this.$refs.searchValue.focus();
    },
    onPopupClose() {
      this.$emit("popupClosed");
    },
    onCloseButtonClicked() {
      this.$refs.roomReservationSearchPopup.hide();
    },
    async onViewButtonClicked() {
      if (!this.searchOptions.searchValue) {
        this.errorToast("검색조건은 필수 입니다");
        return;
      }

      await this.getRoomReservations();
    },
    async getRoomReservations() {
      const data = await GolfErpAPI.fetchRoomReservations({
        arrivalDateFrom: this.searchOptions.arrivalDateFrom,
        arrivalDateTo: this.searchOptions.arrivalDateTo,
        searchValue: this.searchOptions.searchValue,
      });

      this.reservations =
        _orderBy(
          data
            .filter(item => item.rresveStatus !== "CANCEL")
            .map(item => ({
              ...item,
              groupName: item.trResveMember.groupName,
              resveName: item.trResveMember.resveName,
              memberDiv: item.trResveMember.memberDiv,
              memberGrade: item.trResveMember.memberGrade,
              resveContactTel: item.trResveMember.contactTel,
              resveDate: item.trResveMember.resveDate,
              grpName: item.trResveMember.grpName,
              vipFlag: item.trResveMember.vipFlag,
              resveKind: item.trResveMember.resveKind,
              resveChannel: item.trResveMember.resveChannel,
              resveRemarks: item.trResveMember.resveRemarks,
              frontMemo: item.trResveMember.frontMemo,
              roomAmt: item?.trResveStayPaymts?.map(item => item.roomAmt).reduce((acc, cur) => acc + cur, 0),
              isNewMemo: (item?.memoReceivedCount || 0) > (item?.memoConfirmCount || 0),
              roomTypeSortNo: commonCodesGetSortNo("ROOM_TYPE", item.roomType),
              golfResve: (item.trResveMember?.trResveGroupMaps?.filter(groupMap => (groupMap?.tgResveConfirms?.length || 0) > 0).length || 0) > 0 ? "√" : null,
            })), ["arrivalDate", "departureDate", "roomTypeSortNo", "groupName", "resveName"]);

      this.reservationsCount = this.reservations.length;
    },
    onGridHeaderCellInfo() {},
    onGridQueryCellInfo() {},
    onGridDialogDoubleClickedOrEnterKeyed() {
      const selectedRecords = this.$refs.grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        return;
      }

      this.$emit("popupConfirm", selectedRecords[0]);

      this.onCloseButtonClicked();
    },
  },
};
</script>
