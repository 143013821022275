<template>
  <div class="content-wrapper roomReservationRegistration">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기준일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  v-model="searchOptions.bsnDate"
                  type="lookup-condition"
                  :format="'YYYY-MM-DD'"
                  :notClear="true"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="viewButtonClicked"
          >
            조회
          </erp-button>
        </div>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              :is-custom-shortcut-button="true"
              shortcut-key="roomRreservationRegistration.shortcuts.select"
              :shortcut="{key: 'F8'}"
              v-on:click.native="searchReservationButtonClicked"
          >
            예약조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
            button-div="GET"
            @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article
        class="body-article"
        :class="
          isRoomResveDetailViewOpened
            ? $t('className.reservation.reservationDetailViewOpened')
            : ''
        "
      >
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section
            :class="[
              'article-section',
              'section-01',
              'reservation-accordion',
              { 'reservation-close': !isReservationDailyStatusVisible },
            ]"
          >
            <div class="section-header">
              <div class="header-left">
                <div
                  class="header-title"
                  @click="someMethod"
                >
                  예약 목록{{searchOptions.stayDate ? " (예약일자 : " + searchOptions.stayDate + ")" : ""}}
                </div>
                <div class="header-caption">[{{ roomReservationsCount }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="true"
                        v-model="searchOptions.isExcludResveCancel"
                      />
                      <i></i>
                      <div class="label">예약취소 제외</div>
                    </label>
                  </li>
                </ul>
                <ul class="header-button">
                  <li>
                    <erp-button
                        button-div="GET"
                        :is-icon-custom="true"
                        :ignore="isPopupOpened"
                        :is-custom-shortcut-button="true"
                        shortcut-key="roomRreservationRegistration.shortcuts.select"
                        :shortcut="{key: 'F12'}"
                        @click.native="onAvailableRoomButtonClicked"
                    >
                      가용객실
                    </erp-button>
                  </li>
                  <li class="add">
                    <erp-button
                        button-div="SAVE"
                        :ignore="isPopupOpened"
                        :is-icon-custom="true"
                        :is-custom-shortcut-button="true"
                        shortcut-key="roomRreservationRegistration.shortcuts.add"
                        :shortcut="{key: 'F3'}"
                        @click.native="onAddButtonClicked"
                    >
                      추가
                    </erp-button>
                  </li>
                  <li class="print">
                    <erp-button
                        button-div="FILE"
                        @click.native="onClickExcel"
                    >
                      Excel
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-box dev-reservation-daily-status">
                <ejs-grid-wrapper
                  ref="roomReservationDailyStatus"
                  :dataSource="reservationDailyStatus"
                  v-bind="roomReservationDailyStatusGridOptions"
                  @headerCellInfo="onRoomReservationDailyStatusHeaderCellInfo"
                  @queryCellInfo="onRoomReservationDailyStatusQueryCellInfo"
                  @rowSelected="onRoomReservationDailyStatusRowSelected"
                  @actionComplete="onRoomReservationDailyStatusActionComplete"
                />
              </div>
              <div class="body-box dev-room-reservation">
                <ejs-grid-wrapper
                  ref="roomResveGrid"
                  :dataSource="roomReservationsByComputed"
                  v-bind="roomResveGridOptions"
                  @dataBound="onRoomResveGridDataBound"
                  @headerCellInfo="onRoomResveGridHeaderCellInfo"
                  @queryCellInfo="onRoomResveGridQueryCellInfo"
                  @recordClick="onRoomResveGridRecordClick"
                  @rowSelected="onRoomResveGridRowSelected"
                  @actionComplete="onRoomResveGridActionComplete"
                />
              </div>
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">객실 예약 상세</div>
              </div>
            </div>
            <div class="section-caption">
              <ul class="caption-button">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened || !isRoomResveDetailViewOpened"
                      :is-shortcut-button="true"
                      :is-key-color="true"
                      v-on:click.native="onSaveButtonClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :ignore="isPopupOpened || !isRoomResveDetailViewOpened"
                      :is-shortcut-button="true"
                      @click.native="onCancelButtonClicked"
                  >
                    취소
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="SAVE"
                      :ignore="
                        !isRoomResveDetailViewOpened ||
                        isPopupOpened
                      "
                      :is-icon-custom="true"
                      @click.native="sendSMS"
                  >
                    SMS 전송
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onChangeHistoryButtonClicked"
                  >
                    변경이력
                  </erp-button>
                </li>
                <li class="close">
                  <erp-button
                      button-div="CLOSE"
                      @click.native="onRoomReservationDetailViewClosed">
                    닫기
                  </erp-button>
                </li>
              </ul>
            </div>
            <div class="section-body" v-show="isRoomResveDetailViewOpened">
              <article class="body-article article-01">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0201">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">예약정보</div>
                      <div
                        class="header-caption"
                        v-if="roomReservationDetail.reservationInfo.rresveNo"
                      >
                        [예약번호
                        <strong>{{
                            roomReservationDetail.reservationInfo.rresveNo
                          }}</strong
                        >]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article article-0101">
                      <section class="article-section section-020101">
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field groupName">
                                    <!-- 필수 : required -->
                                    <div class="title required">그룹명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                              ref="groupName"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .groupName
                                              "
                                              :maxlength="10"
                                              @change="onGroupNameChanged"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button groupName">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                @click.native="
                                                  onMemberPopupOpen(
                                                    true,
                                                    memberPopupType.GROUP_NAME,
                                                    roomReservationDetail
                                                      .reservationInfo.groupName,
                                                    null
                                                  )
                                                "
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field golfPackageLink">
                                    <!-- 필수 : required -->
                                    <div class="title">골프연결</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li
                                          class="item form-group text"
                                          :style="`${
                                            golfPackageLink ?
                                              'width: calc(100% - 47px);' :
                                              // 'cursor: pointer;' :
                                              'width: calc(100% - 23px);'
                                          }`"
                                        >
                                          {{ golfPackageLink }}
                                        </li>
                                        <li
                                          class="item form-group button"
                                          v-show="golfPackageLink"
                                        >
                                          <ul class="button">
                                            <li class="inputDelete">
                                              <erp-button
                                                button-div="DELETE"
                                                @click.native="onGolfPackageLinkClicked"
                                              >
                                                삭제
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                @click.native="onGolfPackageLinkButtonClicked"
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field grpName">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      단체명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                              ref="grpName"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .grpName
                                              "
                                              @change="onGrpNameChanged"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                @click.native="
                                                  onGroupPopupOpen(true)
                                                "
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                        <li class="item form-group select">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .grpKind
                                              "
                                              :dataSource="
                                                roomReservationViewOptions.grpKindOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                commonCodeFields
                                              "
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group text">
                                          {{
                                            roomReservationDetail.reservationInfo
                                              .grpNo
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field reservationName">
                                    <!-- 필수 : required -->
                                    <div class="title required">예약자명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-text
                                              ref="resveName"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .resveName
                                              "
                                              :maxlength="10"
                                              @change="onResveNameChanged"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                button-div="GET"
                                                @click.native="
                                                  onMemberPopupOpen(
                                                    true,
                                                    memberPopupType.RESV,
                                                    roomReservationDetail
                                                      .reservationInfo.resveName,
                                                    null
                                                  )
                                                "
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">회원번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          {{
                                            memberNoFormatter(roomReservationDetail.reservationInfo
                                              .memberNo)
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">회원구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="memberDiv"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .memberDiv
                                              "
                                              :dataSource="
                                                roomReservationViewOptions.memberDivOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                commonCodeFields
                                              "
                                              cssClass="body-data-dropdown"
                                              @change="
                                                onReservationInfoMemberDivDropdownListChanged
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">회원등급</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="memberGrade"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .memberGrade
                                              "
                                              :dataSource="
                                                roomReservationViewOptions.memberGradeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                commonCodeFields
                                              "
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">연락자명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              ref="contactName"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .contactName
                                              "
                                              :maxlength="10"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">연락처</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div
                                            v-if="commonMixinHasCiperTextGet"
                                            class="form"
                                          >
                                            <component-telephone
                                              ref="contactTelInfo"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .contactTel
                                              "
                                              :max-length="11"
                                              @blur="onContactTelInfoBlur"
                                            />
                                          </div>
                                          <template v-else>
                                            {{
                                              roomReservationDetail.reservationInfo
                                                .contactTel
                                            }}
                                          </template>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">예약종류</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="resveKind"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .resveKind
                                              "
                                              :dataSource="
                                                roomReservationViewOptions.resveKindOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                commonCodeFields
                                              "
                                              cssClass="body-data-dropdown"
                                              @change="onResveKindChanged"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title required">예약채널</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              ref="resveChannel"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .resveChannel
                                              "
                                              :dataSource="
                                                roomReservationViewOptions.resveChannelOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                commonCodeFields
                                              "
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">지역</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .areaCode
                                              "
                                              :dataSource="
                                                roomReservationViewOptions.areaCodeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                commonCodeFields
                                              "
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">성별</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .sexCode
                                              "
                                              :dataSource="
                                                roomReservationViewOptions.sexCodeOptions
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                commonCodeFields
                                              "
                                              cssClass="body-data-dropdown"
                                            ></ejs-dropdownlist>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field remarks">
                                    <!-- 필수 : required -->
                                    <div class="title">비고</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              ref="resveRemarks"
                                              style="height: 47px;"
                                              :resize="false"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .resveRemarks
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field frontMemo">
                                    <!-- 필수 : required -->
                                    <div class="title">프론트메모</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              ref="frontMemo"
                                              style="height: 47px;"
                                              :resize="false"
                                              v-model="
                                                roomReservationDetail.reservationInfo
                                                  .frontMemo
                                              "
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field vipFlag">
                                    <!-- 필수 : required -->
                                    <div class="title">VIP 여부</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <ul class="check">
                                            <li>
                                              <label>
                                                <input
                                                  name="vipFlag"
                                                  type="radio"
                                                  v-model="roomReservationDetail.reservationInfo.vipFlag"
                                                  :value="true"
                                                />
                                                <i></i>
                                                <div class="label">예</div>
                                              </label>
                                            </li>
                                            <li>
                                              <label>
                                                <input
                                                  name="vipFlag"
                                                  type="radio"
                                                  v-model="roomReservationDetail.reservationInfo.vipFlag"
                                                  :value="false"
                                                />
                                                <i></i>
                                                <div class="label">아니오</div>
                                              </label>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field sendSMS">
                                    <!-- 필수 : required -->
                                    <div class="title">SMS 전송</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text-01">
                                          {{
                                            roomReservationDetail.reservationInfo
                                              .smsSendFlag
                                              ? "전송"
                                              : "미전송"
                                          }}
                                        </li>
                                        <li class="item form-group text-02">
                                          {{
                                            roomReservationDetail.reservationInfo
                                              .smsSendCnt
                                          }}건
                                        </li>
                                        <li class="item form-group text">
                                          {{
                                            roomReservationDetail.reservationInfo
                                              .smsSendDt
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field enrollment">
                                    <!-- 필수 : required -->
                                    <div class="title">등록</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text-01">
                                          {{
                                            roomReservationDetail.reservationInfo
                                              .insertName
                                          }}
                                        </li>
                                        <li class="item form-group text">
                                          {{
                                            roomReservationDetail.reservationInfo
                                              .insertDt
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field enrollment">
                                    <!-- 필수 : required -->
                                    <div class="title">수정</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text-01">
                                          {{
                                            roomReservationDetail.reservationInfo
                                              .updateName
                                          }}
                                        </li>
                                        <li class="item form-group text">
                                          {{
                                            roomReservationDetail.reservationInfo
                                              .updateDt
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0202">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">객실 예약 상세</div>
                      <div class="header-caption">[{{ roomReservationDetail.reservationDetailsCount }}건]</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              @click.native="onRoomDetailAddButtonClicked"
                          >
                            객실추가
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              @click.native="onRoomResveWaitButtonClicked"
                          >
                            예약대기
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                            @click.native="onRoomResveConfirmButtonClicked"
                          >
                            예약확정
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              button-div="DELETE"
                              :is-icon-custom="true"
                            @click.native="onRoomReservationCancelButtonClicked"
                          >
                            예약취소
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="section-body"
                    style="height: calc(100% - 43px);"
                    @click.capture="onRoomResveDetailGridClick"
                  >
                    <ejs-grid-wrapper
                      ref="roomResveDetailGrid"
                      :dataSource="roomReservationDetail.reservationDetails"
                      v-bind="roomResveDetailGridOptions"
                      @dataBound="onRoomResveDetailGridDataBound"
                      @headerCellInfo="onRoomResveDetailGridHeaderCellInfo"
                      @queryCellInfo="onRoomResveDetailGridQueryCellInfo"
                      @cellSave="onRoomResveDetailGridCellSave"
                      @cellSaved="onRoomResveDetailGridCellSaved"
                      @recordClick="onRoomResveDetailGridRecordClick"
                      @rowSelected="onRoomResveDetailGridRowSelected"
                      @actionComplete="onRoomResveDetailGridActionComplete"
                      @gridCheckboxChanged="onGridCheckboxChanged"
                      @visitEjsDropdownListEditTemplateChanged="onVisitEjsDropdownListEditTemplateChanged"
                    />
                  </div>
                </section>
              </article>
            </div>
          </section>
        </div>
      </article>
    </div>
    <room-reservation-registration-search-detail-popup
      v-if="isSearchDetailPopupOpen"
      ref="roomReservationRegistrationSearchDetailPopup"
      :searchOptions="searchOptions"
      @popupClosed="onSearchDetailPopupClose"
      @viewButtonClicked="viewButtonClicked"
      @onInitButtonClicked="onInitButtonClicked"
    />
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="memberPopupPosition"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
    <available-room-popup
      v-if="isAvailableRoomPopup"
      ref="availableRoomPopup"
      @popupClosed="onAvailableRoomPopupClosed"
      @popupConfirm="onAvailableRoomPopupConfirm"
    />
    <room-rate-details-by-date-popup
      v-if="isRoomRateDetailsByDatePopup"
      ref="roomRateDetailsByDatePopup"
      @popupClosed="onRoomRateDetailsByDatePopupClosed"
    />
    <room-reservation-cancel-popup
      v-if="isRoomReservationCancelPopup"
      ref="roomReservationCancelPopup"
      @popupClosed="onRoomReservationCancelPopupClosed"
    />
    <golf-package-link-popup
      v-if="isGolfPackageLinkPopup"
      ref="golfPackageLinkPopup"
      @popupClosed="onGolfPackageLinkPopupClosed"
    />
    <vacant-room-popup
      v-if="isVacantRoomPopup"
      ref="vacantRoomPopup"
      @popupClosed="onVacantRoomPopupClosed"
    />
    <unlink-package-popup
      v-if="isUnlinkPackagePopup"
      ref="unlinkPackagePopup"
      @popupClosed="onUnlinkPackagePopupClosed"
    />
    <reservation-sms-send-popup
      v-if="isReservationSmsSendPopup"
      ref="reservationSmsSendPopup"
      @popupClosed="onReservationSmsSendPopupClosed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClose"
    />
    <room-reservation-search-popup
      v-if="isRoomReservationSearchPopup"
      ref="roomReservationSearchPopup"
      @popupClosed="onRoomReservationSearchPopupClosed"
      @popupConfirm="onRoomReservationSearchPopupConfirm"
    />
  </div>
</template>

<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%}
body .appContent .body-article .article-right{width:590px;margin:0 -590px 0 0}
body .appContent .body-article.dev-reservation-detail-view-opened .article-left{width:calc(100% - 590px)}
body .appContent .body-article.dev-reservation-detail-view-opened .article-right{margin:0}

body .appContent .article-section.section-01 .section-body{overflow:hidden;border:none}
body .appContent .article-section.section-01 .section-body {display: flex; flex-direction: row; overflow: hidden; padding: 0 1px; border: none;}
body .appContent .article-section.section-01 .section-body:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-01 .body-box.dev-reservation-daily-status .body-grid >>> .e-grid.e-lib .e-gridheader {border-top-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-box:not(.dev-reservation-daily-status) {overflow: hidden; float: left; height: 100%; margin: 0 -1px;}
body .appContent .article-section.section-01 .section-body .body-box.dev-room-reservation {width: calc(100% - 345px);}
body .appContent .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-room-reservation {width: calc(100% - 100px);}
body .appContent .article-section.section-01.reservation-accordion > .section-header .header-title {padding: 0 0 0 24px; background: transparent url('../../../assets/images/common/accordion-left.png') no-repeat left center; cursor: pointer;}
body .appContent .article-section.section-01.reservation-accordion.reservation-close > .section-header .header-title {padding: 0 0 0 24px; background: transparent url('../../../assets/images/common/accordion-right.png') no-repeat left center; cursor: pointer;}
body .appContent .article-section.section-01.reservation-accordion.reservation-close .section-body .body-box.dev-reservation-daily-status {
  width: 100px;
}
body .appContent .article-section.section-01.reservation-accordion.reservation-close .section-body {
  border-left: 1px dotted #ccc !important;
}
body .appContent .article-section.section-01 .section-body .body-box.dev-reservation-daily-status {transition: all 0.3s ease-out; width: 345px; margin: 0; border: 1px solid #000000;}
body .appContent .article-section.section-01 .section-body .body-box.dev-reservation-daily-status .body-grid {height: 100%;}

body .appContent .article-section.section-02 .section-body{overflow-y:scroll}
body .appContent .article-section.section-02 .body-article .article-section{height:auto}
body .appContent .article-section.section-02 .body-article .section-header{width:auto}
body .appContent .article-section.section-02 .body-article .section-body{overflow:visible;border:none}

body .appContent .article-section.section-0201 .section-body .body-article.article-0101 {padding: 0;}
body .appContent .article-section.section-0201 .section-body .body-article.article-0101 .article-section.section-020101 {padding: 0;}

body .appContent .article-section.section-02 .body-article .article-section.section-0202 {height: calc(100% - 428px);}
body .appContent .article-section.section-02 .body-article .article-section.section-0202 .section-body {border: none;}

body .appContent .article-section.section-020101 .body-data .field .title {width: 90px;}
body .appContent .article-section.section-020101 .body-data .field .content {width: calc((100% - 90px) + 1px);}
body .appContent .article-section.section-020101 .body-data .field.groupName {width: calc(100% + 1px);}
body .appContent .article-section.section-020101 .body-data .field.groupName .content .item.input {width: 145px;}
body .appContent .article-section.section-020101 .body-data .field.groupName .content .item.button.groupName {border-right: 1px solid #e0e0e0;}
body .appContent .article-section.section-020101 .body-data .field.golfPackageLink {width: calc(100% + 1px);}
/*body .appContent .article-section.section-020101 .body-data .field.golfPackageLink .content .item.text {width: calc(100% - 47px); text-align: center;}*/
/*body .appContent .article-section.section-020101 .body-data .field.golfPackageLink .content .item.text:hover {text-decoration: underline;}*/
body .appContent .article-section.section-020101 .body-data .field.grpName {width: calc(100% + 1px);}
body .appContent .article-section.section-020101 .body-data .field.grpName .content .item.input {width: 145px;}
body .appContent .article-section.section-020101 .body-data .field.grpName .content .item.select {width: 89px;}
body .appContent .article-section.section-020101 .body-data .field.reservationName .content .item.input {width: calc(100% - 23px);}

body .appContent .article-section.section-020101 .body-data .field .title {width: 90px;}
body .appContent .article-section.section-020101 .body-data .field .content {width: calc((100% - 90px) + 1px);}
body .appContent .article-section.section-020101 .body-data .field.remarks {width: calc(100% + 1px);}
body .appContent .article-section.section-020101 .body-data .field.frontMemo {width: calc(100% + 1px);}
body .appContent .article-section.section-020101 .body-data .field.vipFlag {width: calc(100% + 1px);}
body .appContent .article-section.section-020101 .body-data .field.sendSMS {width: calc(100% + 1px);}
body .appContent .article-section.section-020101 .body-data .field.sendSMS .content .item.text-01 {width: 126px;}
body .appContent .article-section.section-020101 .body-data .field.sendSMS .content .item.text-02 {width: 43px;}
body .appContent .article-section.section-020101 .body-data .field.enrollment {width: calc(100% + 1px);}
body .appContent .article-section.section-020101 .body-data .field.enrollment .content .item.text-01 {width: 169px;}
</style>

<script>
import Vue from 'vue';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import ComponentTelephone from "@/components/ComponentTelephone";
import MemberSelectPopup from "@/views/common/MemberSelectPopup";
import GroupPopup from "@/views/golf-reservation/popup/GroupPopup";
import AvailableRoomPopup from "@/views/room-reservation-management/popup/AvailableRoomPopup";
import RoomRateDetailsByDatePopup from "@/views/room-reservation-management/popup/RoomRateDetailsByDatePopup";
import RoomReservationCancelPopup from "@/views/room-reservation-management/popup/RoomReservationCancelPopup";
import GolfPackageLinkPopup from "@/views/room-reservation-management/popup/GolfPackageLinkPopup";
import VacantRoomPopup from "@/views/room-reservation-management/popup/VacantRoomPopup";
import UnlinkPackagePopup from "@/views/room-reservation-management/popup/UnlinkPackagePopup";
import ReservationSmsSendPopup from "@/views/golf-reservation/popup/ReservationSmsSendPopup";
import RoomReservationRegistrationSearchDetailPopup from "@/views/room-reservation-management/room-reservation/popup/RoomReservationRegistrationSearchDetailPopup";
import HistoryPopup from "@/views/common/HistoryPopup.vue";
import RoomReservationSearchPopup from "@/views/room-reservation-management/room-reservation/popup/RoomReservationSearchPopup.vue";
import ErpButton from "@/components/button/ErpButton.vue";

import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedDate, getTodayDate, getDateOfNext, getDayOfWeekCaption,} from "@/utils/date";
import { formPreventEnterEvent, validateFormRefs, } from "@/utils/formUtil";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCommonCodeAttrbNameByCodeAndIdx,
  commonCodesGetComName,
  commonCodesGetJsonData,
  commonCodesGetSortNo,
  commonCodesGetStandardInfo,
  commonCodesGetColorValue,
} from "@/utils/commonCodes";
import { memberNoFormatter } from "@/utils/formatter";
import {getGroupList} from "@/api/group";
import {gridUtilGetTelColumnAccess} from "@/utils/gridUtil";
import moment from "moment";
import {deepDiffs} from "@/utils/ObjectUtil";
import { orderBy as _orderBy, maxBy as _maxBy, minBy as _minBy, groupBy as _groupBy, } from "lodash";
import {mapActions, mapState} from "vuex";
import gridVisitEjsDropdownlistEditTemplate
  from '@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate';
import InputDate from "@/components/common/datetime/InputDate";
import {subStrByte} from "@/utils/string";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: "RoomReservationRegistration",
  components: {
    InputDate,
    EjsGridWrapper,
    InputText,
    InputTextarea,
    ComponentTelephone,
    MemberSelectPopup,
    GroupPopup,
    AvailableRoomPopup,
    RoomRateDetailsByDatePopup,
    RoomReservationCancelPopup,
    GolfPackageLinkPopup,
    VacantRoomPopup,
    UnlinkPackagePopup,
    ReservationSmsSendPopup,
    RoomReservationRegistrationSearchDetailPopup,
    HistoryPopup,
    RoomReservationSearchPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      isRoomReservationSaveButtonClicked: false,
      isReservationDailyStatusGridAutoSelectRow: true, // 재조회시 첫번째 Row 자동 Select 여부.
      isQuestionReservationDetailModify: false, // "수정 내역이 있습니다. 진행하시겠습니까?" 두번 질문 방지용.
      isPreResveDateReturnFlag: false,
      isReservationDailyStatusVisible: true,
      gridEjsDropdownlistEditTemplateEventBus: new Vue(),
      selectStayId: null,
      findStayId: null,
      findRowData: null,
      isMemberSelectPopupOpen: false,
      isGroupPopupOpen: false,
      isAvailableRoomPopup: false,
      isRoomRateDetailsByDatePopup: false,
      isRoomReservationCancelPopup: false,
      isGolfPackageLinkPopup: false,
      isVacantRoomPopup: false,
      isUnlinkPackagePopup: false,
      isReservationSmsSendPopup: false,
      isHistoryPopupOpen: false,
      isRoomReservationSearchPopup: false,
      memberPopupPosition: { x: "center", y: "center" },
      memberPopupType: {
        RESV: "RESV", // 예약자
        TRANS: "TRANS", // 위임자
        COMP: "COMP", // 동반자
        GROUP_NAME: "GROUP_NAME", // 그룹명(객실)
      },
      searchOptions: {
        bsnDate: null,
        stayDate: null,
        preStayDate: null,
        groupName: null,
        resveName: null,
        arrivalDateRangeValue: { to: null, from: null },
        isExcludResveCancel: true,
        rresveNo: null,
        rresveStatusList: [],
        rresveStatusCodes: commonCodesGetCommonCode("RRESVE_STATUS", true),
        isDepartureDateUse: false,
        departureDateRangeValue: { to: null, from: null },
        isResveDateUse: false,
        resveDateRangeValue: { to: null, from: null },
        resveKind: "ALL",
        resveKindCodes: [],
        resveChannel: "ALL",
        resveChannelCodes: [],
        roomType: "ALL",
        roomTypeCodes: [],
        memberDiv: "ALL",
        memberDivCodes: [],
        memberGrade: "ALL",
        memberGradeCodes: [],
        roomSaleKind: "ALL",
        roomSaleKindCodes: [],
        roomDcKind: "ALL",
        roomDcKindCodes: [],
        isGolfPackage: false,
      },
      commonCodeFields: {text: 'comName', value: 'comCode'},
      isRoomResveDetailViewOpened: false,
      reservationDailyStatus: [],
      roomReservations: [],
      roomReservationsCount: 0,
      isSearchDetailPopupOpen: false,
      orgRoomReservationDetail: {
        reservationInfo: {
          rresveNo: null,
          resveDate: null,
          groupName: null,
          grpNo: null,
          grpName: null,
          grpKind: null,
          resveName: null,
          membershipId: null,
          memberNo: null,
          memberDiv: null,
          memberGrade: null,
          contactName: null,
          contactTel: null,
          resveKind: null,
          resveChannel: null,
          areaCode: null,
          sexCode: null,
          resveRemarks: null,
          frontMemo: null,
          vipFlag: null,
          smsSendFlag: null,
          smsSendCnt: null,
          smsSendDt: null,
          insertName: null,
          insertDt: null,
          trResveGroupMaps: [],
        },
      },
      roomReservationDetail: {
        reservationInfo: {
          rresveNo: null,
          resveDate: null,
          groupName: null,
          grpNo: null,
          grpName: null,
          grpKind: null,
          resveName: null,
          membershipId: null,
          memberNo: null,
          memberDiv: null,
          memberGrade: null,
          contactName: null,
          contactTel: null,
          resveKind: null,
          resveChannel: null,
          areaCode: null,
          sexCode: null,
          resveRemarks: null,
          frontMemo: null,
          vipFlag: null,
          smsSendFlag: null,
          smsSendCnt: null,
          smsSendDt: null,
          insertName: null,
          insertDt: null,
          trResveGroupMaps: [],
        },
        reservationDetails: [],
        reservationDetailsCount: 0,
      },
      roomReservationViewOptions: {
        sexCodeOptions: commonCodesGetCommonCode("SEX_CODE", true),
        resveChannelOptions: commonCodesGetCommonCode("RESVE_CHANNEL", true),
        resveKindOptions: commonCodesGetCommonCode("RESVE_KIND", true),
        memberGradeOptions: commonCodesGetCommonCode("MEMBER_GRADE", true),
        memberDivOptions: commonCodesGetCommonCode("MEMBER_DIV", true),
        grpKindOptions: commonCodesGetCommonCode("GRP_KIND", true),
        areaCodeOptions: commonCodesGetCommonCode("AREA_CODE", true),
      },
      roomReservationInfoValidationRules: {
        groupName: {
          required: true,
        },
        resveName: {
          required: true,
        },
        memberDiv: {
          required: true,
        },
        memberGrade: {
          required: true,
        },
        contactName: {
          required: true,
        },
      },
    };
  },
  created() {
    this.searchOptions.bsnDate = getFormattedDate(new Date());
    this.searchOptions.departureDateRangeValue.from = getFormattedDate(new Date());
    this.searchOptions.departureDateRangeValue.to = getFormattedDate(new Date());
    this.searchOptions.resveDateRangeValue.from = getFormattedDate(new Date());
    this.searchOptions.resveDateRangeValue.to = getFormattedDate(new Date());

    this.searchOptions.resveKindCodes = commonCodesGetCommonCode("RESVE_KIND", true);
    if (
      this.searchOptions.resveKindCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.resveKindCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.resveChannelCodes = commonCodesGetCommonCode("RESVE_CHANNEL", true);
    if (
      this.searchOptions.resveChannelCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.resveChannelCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomTypeCodes = commonCodesGetCommonCode("ROOM_TYPE", true);
    if (
      this.searchOptions.roomTypeCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.roomTypeCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.memberDivCodes = commonCodesGetCommonCode("MEMBER_DIV", true);
    if (
      this.searchOptions.memberDivCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.memberDivCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.memberGradeCodes = commonCodesGetCommonCode("MEMBER_GRADE", true);
    if (
      this.searchOptions.memberGradeCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.memberGradeCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomSaleKindCodes = commonCodesGetCommonCode("ROOM_SALE_KIND", true);
    if (
      this.searchOptions.roomSaleKindCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.roomSaleKindCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchOptions.roomDcKindCodes = commonCodesGetCommonCode("ROOM_DC_KIND", true);
    if (
      this.searchOptions.roomDcKindCodes.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.roomDcKindCodes.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.$EventBus.$on("memoConfirm", (args) => {
      this.memoConfirm(args);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("memoConfirm");
  },
  computed: {
    ...mapState("memoView", [
      "memoStayId",
    ]),
    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
    golfPackageLink() {
      return subStrByte(
        Object.entries(
          _groupBy(
            _orderBy(
              this.roomReservationDetail.reservationInfo?.trResveGroupMaps.map(trResveGroupMap =>
                trResveGroupMap?.tgResveConfirms.map(tgResveConfirm => {
                  return {
                    resveDateByYYMMDD: getFormattedDate(tgResveConfirm.reservationTime.resveDate, "YY.MM.DD"),
                    dwName: commonCodesGetComName("DW_CODE", tgResveConfirm.reservationTime.dwCode),
                    resveCourse: tgResveConfirm.reservationTime.resveCourse,
                    resveCourseSortNo: commonCodesGetSortNo("COURSE_CODE", tgResveConfirm.reservationTime.resveCourse),
                    resveTime: tgResveConfirm.reservationTime.resveTime
                  };
                })
              ).flat(),
              ["resveDateByYYMMDD", "resveCourseSortNo", "resveTime"]
            ),
            "resveDateByYYMMDD"
          )
        ).map(([key, value]) =>
          key + "(" + value[0].dwName + ") " +
          Object.entries(_groupBy(value, "resveCourse"))
            .map(([key, value]) =>
              commonCodesGetComName("COURSE_CODE", key) + " " + value.map(item => item.resveTime).join(", ")
            ).join(", ")
        ).join(", "), 64);
    },
    isPopupOpened() {
      return (
        this.isMemberSelectPopupOpen ||
        this.isGroupPopupOpen ||
        this.isAvailableRoomPopup ||
        this.isRoomRateDetailsByDatePopup ||
        this.isRoomReservationCancelPopup ||
        this.isGolfPackageLinkPopup ||
        this.isVacantRoomPopup ||
        this.isUnlinkPackagePopup ||
        this.isReservationSmsSendPopup ||
        this.isSearchDetailPopupOpen ||
        this.isHistoryPopupOpen ||
        this.isRoomReservationSearchPopup
      );
    },
    reservationSearchButtonProps() {
      return {
        shortcutKey: "RoomReservationRegistration.shortcuts.reservationSearch",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F8,
        },
      };
    },
    availableRoomButtonProps() {
      return {
        shortcutKey: "RoomReservationRegistration.shortcuts.availableRoom",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F12,
        },
      };
    },
    sendSmsButtonProps() {
      return {
        shortcutKey: "RoomReservationRegistration.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
    roomReservationDailyStatusGridOptions() {
      const roomTypes = commonCodesGetCommonCode("ROOM_TYPE");
      const columns = [
        {
          field: "bsnDate",
          type: "string",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "bsnDateByMmDd",
          headerText: "일자",
          type: "string",
          minWidth: 16,
          width: 50,
          textAlign: "center",
        },
        {
          field: "dwCode",
          headerText: "요일",
          type: "string",
          minWidth: 16,
          width: 35,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "DW_CODE",
        }
      ];

      roomTypes.forEach(roomType => {
        columns.push({
          headerText: roomType.comName,
          columns: [
            {
              field: "remainRoomCountBy" + roomType.comCode,
              headerText: "잔여",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 35,
              textAlign: "center",
            },
            {
              field: "useRoomCountBy" + roomType.comCode,
              headerText: "사용",
              type: "number",
              format: "N",
              minWidth: 16,
              width: 35,
              textAlign: "center",
            }
          ],
        });
      });
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        allowSorting: false,
        allowFiltering: false,
        isSelectedRowRetain: false,
        isShowProgress: false,
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        noColumnWidth: 30,
        columns: columns,
      };
    },
    roomResveGridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: {pageSize: 50},
        columns: [
          {
            field: "stayId",
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_TYPE",
          },
          {
            field: "resveName",
            headerText: "예약자",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "grpName",
            headerText: "단체명",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "rresveStatus",
            headerText: "예약상태",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RRESVE_STATUS",
          },
          {
            field: "memo",
            headerText: "",
            type: "string",
            minWidth: 16,
            maxWidth: 20,
            width: 20,
            allowEditing: false,
            allowFiltering: false,
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "stayCnt",
            headerText: "박수",
            type: "string",
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "roomAmt",
            headerText: "객실료",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
          {
            field: "resveRemarks",
            headerText: "예약비고",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "center",
          },
          {
            field: "frontMemo",
            headerText: "프론트메모",
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "center",
          },
          {
            field: "golfResve",
            headerText: "골프예약",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "rresveNo",
            headerText: "예약번호",
            type: "string",
            minWidth: 16,
            width: 100,
          },
          {
            field: "roomNo",
            headerText: "객실번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "groupName",
            headerText: "그룹명",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "resveContactTel",
            headerText: "연락처",
            type: "string",
            minWidth: 16,
            width: 100,
            textAlign: "center",
            valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
          },
          {
            field: "guestName",
            headerText: "투숙객",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "memberDiv",
            headerText: "회원구분",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_DIV",
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_GRADE",
          },
          {
            field: "roomSaleKind",
            headerText: "판매유형",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_SALE_KIND",
          },
          {
            field: "roomDcKind",
            headerText: "할인유형",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_DC_KIND",
          },
          {
            field: "vipFlag",
            headerText: "VIP",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "resveKind",
            headerText: "예약종류",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RESVE_KIND",
          },
          {
            field: "resveChannel",
            headerText: "예약채널",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RESVE_CHANNEL",
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
          },
          {
            field: "updateName",
            headerText: "수정자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
          },
        ],
      };
    },
    roomResveDetailGridOptions() {
      return {
        provides: [
          Edit,
          Filter,
          Resize,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        allowEditing: true,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: true,
        noColumnWidth: 40,
        isSelectedRowRetain: false,
        isAutoSelectRow: false,
        isAutoSelectCell: false,
        columns: [
          {
            field: "stayId",
            type: "number",
            visible: false,
          },
          {
            field: "reprsntRoomFlag",
            headerText: "대표",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
            visible: false,
          },
          {
            field: "frpyAbleFlag",
            headerText: "후불",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
            visible: false,
          },
          {
            field: "guestName",
            headerText: "투숙객",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "arrivalDate",
            headerText: "입실일자",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "stayCnt",
            headerText: "박",
            type: "number",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 3,
            },
            minWidth: 16,
            width: 40,
            textAlign: "center",
          },
          {
            field: "departureDate",
            headerText: "퇴실일자",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "memo",
            headerText: "",
            type: "string",
            minWidth: 16,
            maxWidth: 20,
            width: 20,
            allowEditing: false,
            allowFiltering: false,
          },
          {
            field: "roomType",
            headerText: "객실타입",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            editTemplate: () =>
              gridVisitEjsDropdownlistEditTemplate(
                "roomType",
                commonCodesGetCommonCode("ROOM_TYPE"),
                this.$refs.roomResveDetailGrid,
                this.gridEjsDropdownlistEditTemplateEventBus,
              ),
            valueAccessor: (field, data) => {
              const commonCode = commonCodesGetCommonCode(
                'ROOM_TYPE',
              ).find((commonCode) => commonCode.comCode === data[field]);
              if (commonCode) {
                return commonCode.comName;
              } else {
                return null;
              }
            },
          },
          {
            field: "roomNo",
            headerText: "객실번호",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "vacantRoomIcon",
            headerText: "",
            type: "string",
            minWidth: 16,
            width: 25,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "rresveStatus",
            headerText: "예약상태",
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "RRESVE_STATUS",
            allowEditing: false,
          },
          {
            field: "roomSaleKind",
            headerText: "판매유형",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            editTemplate: () =>
              gridVisitEjsDropdownlistEditTemplate(
                "roomSaleKind",
                commonCodesGetCommonCode("ROOM_SALE_KIND"),
                this.$refs.roomResveDetailGrid,
                this.gridEjsDropdownlistEditTemplateEventBus,
              ),
            valueAccessor: (field, data) => {
              const commonCode = commonCodesGetCommonCode(
                'ROOM_SALE_KIND',
              ).find((commonCode) => commonCode.comCode === data[field]);
              if (commonCode) {
                return commonCode.comName;
              } else {
                return null;
              }
            },
          },
          {
            field: "roomDcKind",
            headerText: "할인유형",
            type: "string",
            editType: "dropdownedit",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "ROOM_DC_KIND",
          },
          {
            field: "roomAmt",
            headerText: "객실료",
            type: "number",
            format: "N",
            minWidth: 16,
            width: 90,
            textAlign: "right",
            allowEditing: false,
          },
          {
            field: "dcContents",
            headerText: "할인내용",
            type: "string",
            minWidth: 16,
            width: 150,
          },
          {
            field: "vehicleNo",
            headerText: "차량번호",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            field: "contactTel",
            headerText: "연락처",
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            editType: "telephoneedit",
            valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
          },
          {
            field: "adultCnt",
            headerText: "성인",
            type: "number",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 3,
            },
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "childCnt",
            headerText: "소아",
            type: "number",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: false,
              allowMinus: false,
              maxLength: 3,
            },
            minWidth: 16,
            width: 60,
            textAlign: "center",
          },
          {
            field: "insertName",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "updateName",
            headerText: "수정자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
            allowEditing: false,
          },
          {
            field: "roomId",
            type: "string",
            visible: false,
          },
        ],
        validationModification: false,
        validationRules: {
          guestName: {
            required: true,
            maxLength: 100,
          },
          arrivalDate: {
            required: true,
            dateFormat: true,
          },
          stayCnt: {
            required: true,
          },
          departureDate: {
            required: true,
            dateFormat: true,
          },
          roomType: {
            required: true,
          },
        },
      };
    },
    roomReservationsByComputed() {
      return this.roomReservations.filter(item => this.searchOptions.isExcludResveCancel ? item.rresveStatus !== "CANCEL" : true);
    },
  },
  async mounted() {
    const {roomType, roomNo, roomId, bsnDate, stayId, resveNo} = this.$route.query;
    if (roomType && roomNo && roomId) {
      await this.onAddButtonClicked(null, {
        roomType: roomType,
        roomNo: roomNo,
        roomId: roomId,
      });
      this.$router.replace({query: null});
    } else if(bsnDate && stayId) {
      this.$nextTick(async () => {
        this.searchOptions.bsnDate = bsnDate;
        await this.viewButtonClicked();
        setTimeout(async () => {
          const { rresveNo } = this.$refs.roomResveGrid.getBatchCurrentViewRecords().find((record) => record.stayId === Number(stayId));
          await this.getRoomReservationDetail(rresveNo);
        }, 300);
      });
    } else if (resveNo) {
      this.$nextTick(() => {
        this.roomAddSettingBygolfResveNo(resveNo, roomType, bsnDate);
        this.$router.replace({query: null});
      });
    } else {
      await this.viewButtonClicked();
    }
  },
  methods: {
    ...mapActions("memoView", ["setStayId", "clearMemo"]),
    memberNoFormatter,
    formPreventEnterEvent,
    validateFormRefs,
    someMethod() {
      this.isReservationDailyStatusVisible = !this.isReservationDailyStatusVisible;
    },
    async viewButtonClicked() {
      await this.fetchRoomReservationStatusList();
      // await this.fetchRoomReservations();
      //
      // if (this.roomReservationDetail.reservationInfo.rresveNo) {
      //   if (this.isReservationDetailChanged()) {
      //     if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
      //       return;
      //     }
      //   }
      //   await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      // }
    },
    async fetchRoomReservationStatusList(autoSelectRow = false) {
      if (this.searchOptions.stayDate) {
        this.isReservationDailyStatusGridAutoSelectRow = autoSelectRow;
      }

      const data = await GolfErpAPI.fetchRoomReservationStatusList({
        bsnDate: this.searchOptions.bsnDate,
      });

      this.reservationDailyStatus = data.map(item => {
        const rtnData = {
          ...item,
          bsnDateByMmDd: getFormattedDate(item.bsnDate, "MM/DD"),
        };

        const roomTypes = commonCodesGetCommonCode("ROOM_TYPE");

        roomTypes.forEach(roomType => {
          rtnData["remainRoomCountBy" + roomType.comCode] =
            item.roomStatusRecords
              .filter(record => record.roomType === roomType.comCode)
              .map(record => record.remainRoomCount)
              .reduce((acc, cur) => acc + cur);
          rtnData["useRoomCountBy" + roomType.comCode] =
            item.roomStatusRecords
              .filter(record => record.roomType === roomType.comCode)
              .map(record => record.useRoomCount)
              .reduce((acc, cur) => acc + cur);
        });

        return rtnData;
      });

      if (
        !(this.reservationDailyStatus.filter(data => data.bsnDate === this.searchOptions.stayDate).length > 0)
      ) {
        this.isReservationDailyStatusGridAutoSelectRow = true;
      }
    },
    async fetchRoomReservations() {
      const data =
        await GolfErpAPI.fetchRoomReservations({
          stayDate: this.searchOptions.stayDate,
          // arrivalDateFrom: this.searchOptions.arrivalDateRangeValue.from,
          // arrivalDateTo: this.searchOptions.arrivalDateRangeValue.to,
          groupName: this.searchOptions.groupName,
          resveName: this.searchOptions.resveName,
          // isExcludResveCancel: this.searchOptions.isExcludResveCancel,
          rresveNo: this.searchOptions.rresveNo,
          rresveStatusList: this.searchOptions.rresveStatusList,
          departureDateFrom: !this.searchOptions.isDepartureDateUse ? null : this.searchOptions.departureDateRangeValue.from,
          departureDateTo: !this.searchOptions.isDepartureDateUse ? null : this.searchOptions.departureDateRangeValue.to,
          resveDateFrom: !this.searchOptions.isResveDateUse ? null : this.searchOptions.resveDateRangeValue.from,
          resveDateTo: !this.searchOptions.isResveDateUse ? null : this.searchOptions.resveDateRangeValue.to,
          resveKind: this.searchOptions.resveKind === "ALL" ? null : this.searchOptions.resveKind,
          resveChannel: this.searchOptions.resveChannel === "ALL" ? null : this.searchOptions.resveChannel,
          roomType: this.searchOptions.roomType === "ALL" ? null : this.searchOptions.roomType,
          memberDiv: this.searchOptions.memberDiv === "ALL" ? null : this.searchOptions.memberDiv,
          memberGrade: this.searchOptions.memberGrade === "ALL" ? null : this.searchOptions.memberGrade,
          roomSaleKind: this.searchOptions.roomSaleKind === "ALL" ? null : this.searchOptions.roomSaleKind,
          roomDcKind: this.searchOptions.roomDcKind === "ALL" ? null : this.searchOptions.roomDcKind,
          isGolfPackage : this.searchOptions.isGolfPackage,
        });

      this.roomReservations =
        _orderBy(
          data.map(item => ({
            ...item,
            groupName: item.trResveMember.groupName,
            resveName: item.trResveMember.resveName,
            memberDiv: item.trResveMember.memberDiv,
            memberGrade: item.trResveMember.memberGrade,
            resveContactTel: item.trResveMember.contactTel,
            resveDate: item.trResveMember.resveDate,
            grpName: item.trResveMember.grpName,
            vipFlag: item.trResveMember.vipFlag,
            resveKind: item.trResveMember.resveKind,
            resveChannel: item.trResveMember.resveChannel,
            resveRemarks: item.trResveMember.resveRemarks,
            frontMemo: item.trResveMember.frontMemo,
            roomAmt: item?.trResveStayPaymts?.map(item => item.roomAmt).reduce((acc, cur) => acc + cur, 0),
            isNewMemo: (item?.memoReceivedCount || 0) > (item?.memoConfirmCount || 0),
            roomTypeSortNo: commonCodesGetSortNo("ROOM_TYPE", item.roomType),
            golfResve: (item.trResveMember?.trResveGroupMaps?.filter(groupMap => (groupMap?.tgResveConfirms?.length || 0) > 0).length || 0) > 0 ? "√" : null,
          })), ["roomTypeSortNo", "arrivalDate", "departureDate", "groupName", "resveName"]);
      this.roomReservationsCount = this.roomReservations.length;
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
    },
    onAvailableRoomButtonClicked() {
      this.isAvailableRoomPopup = true;

      this.$nextTick(() => {
        this.$refs.availableRoomPopup.showPopup({
          bsnDate: moment().format("YYYY-MM-DD"),
        });
      });
    },
    async onRoomReservationCancelButtonClicked() {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      const grid = this.$refs.roomResveDetailGrid;
      const selectedRecords = grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("선택된 예약 상세가 존재하지 않습니다");
        return;
      }

      if (selectedRecords[0].rresveStatus === "CANCEL") {
        this.errorToast("이미 취소된 객실입니다");
        return;
      }

      if (
        selectedRecords
          .filter(item => commonCodesGetCommonCodeAttrbByCodeAndIdx("RRESVE_STATUS", item.rresveStatus, 1) === "STAY").length > 0
      ) {
        this.errorToast("체크인 또는 체크아웃 상태라 예약 취소가 불가능합니다");
        return;
      }

      if (selectedRecords[0].stayId) {
        this.isRoomReservationCancelPopup = true;

        this.$nextTick(() => {
          this.$refs.roomReservationCancelPopup.showPopup({
            rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
            stayIds: selectedRecords.map(record => record.stayId),
          });
        });
      } else {
        grid.deleteRecord();
      }
    },
    async onAddButtonClicked(event, args) {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      this.isRoomResveDetailViewOpened = true;
      this.reservationInfoInit();

      this.$refs.roomResveGrid.clearSelection();

      this.$nextTick(() => {
        setTimeout(() => {
          if (args) {
            this.onRoomDetailAddButtonClicked(null, args);
          }
          this.$refs.groupName.focus();
        }, 500);
      });
    },
    onClickExcel() {
      this.$refs.roomResveGrid.excelExport();
    },
    onRoomResveGridDataBound() {},
    onRoomResveGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        },
        node: {
          classList,
        }
      } = args;

      if (["resveName", "groupName"].includes(field)) {
        classList.add(this.$t("className.grid.clickAreaLeft"));
      } else if (field === "memo") {
        classList.add(this.$t("className.grid.memoArea"));
      }
    },
    onRoomResveGridQueryCellInfo(args) {
      const {
        column: {
          field,
        },
        cell: {
          classList,
          style,
        },
        data,
      } = args;

      if (data.rresveStatus === "CANCEL") {
        style.textDecoration = "line-through";
      }

      if (["roomType", "resveName", "grpName", "rresveStatus", "groupName"].includes(field)) {
        classList.add(this.$t("className.grid.clickAreaLeft"));
      } else if (field === "memo") {
        if (data.memoAllCount > 0) {
          if (data.isNewMemo && commonCodesGetStandardInfo("memoConfirmFlag")) {
            classList.add(this.$t("className.grid.newMemoArea"));
          } else {
            classList.add(this.$t("className.grid.memoArea"));
          }
        }
      } else if (field === "rresveStatus") {
        style.color = commonCodesGetColorValue("RRESVE_STATUS", data["rresveStatus"]);
      }
    },
    async onRoomResveGridRecordClick(args) {
      const {
        column: {
          field,
        },
        rowData,
      } = args;

      if (["roomType", "resveName", "grpName", "rresveStatus", "groupName", "memo"].includes(field)) {
        if (this.isReservationDetailChanged()) {
          if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }

        this.selectStayId = rowData["stayId"];

        this.getRoomReservationDetail(rowData["rresveNo"]);

        if (field === "memo" || rowData.memoAllCount > 0) {
          this.$EventBus.$emit("memoShortcutButtonClick", true); // 메모창 열기
        }
      }

      this.findStayId = null;
      this.findRowData = null;
    },
    async onRoomResveGridRowSelected(args) {
      const {
        data,
      } = args;

      if (data.stayId && !this.findStayId) {
        this.selectStayId = data.stayId;
        await this.setStayId(data.stayId);
      } else if (this.findStayId) {
        this.selectStayId = this.findStayId;
        await this.setStayId(this.findStayId);
      }
    },
    async onRoomResveGridActionComplete(args) {
      const {
        requestType,
        action
      } = args;

      if (requestType === "filtering") {
        if (action === "clearFilter") {
          this.roomReservationsCount = this.roomReservations.length;
        } else if ((args?.rows?.length || 0) > 0) {
          const filteredRecords = this.$refs.roomResveGrid.getFilteredRecords();
          this.roomReservationsCount = filteredRecords.length || 0;
        } else {
          this.roomReservationsCount = 0;
        }
      } else if (requestType === "refresh") {
        if (this.findStayId) {
          const rowIndex = this.$refs.roomResveGrid.getRowIndexByPrimaryKey(this.findStayId);
          this.$refs.roomResveGrid.selectRow(rowIndex);
          await this.onRoomResveGridRecordClick({
            column: {
              field: "resveName",
            },
            rowData: this.findRowData,
          });
        }
      }
    },
    onInitButtonClicked() {
      this.searchOptions.rresveNo = null;
      this.searchOptions.rresveStatusList = [];
      this.searchOptions.isDepartureDateUse = false;
      this.searchOptions.isResveDateUse = false;
      this.searchOptions.departureDateRangeValue.from = getFormattedDate(new Date());
      this.searchOptions.departureDateRangeValue.to = getFormattedDate(new Date());
      this.searchOptions.resveDateRangeValue.from = getFormattedDate(new Date());
      this.searchOptions.resveDateRangeValue.to = getFormattedDate(new Date());
      this.searchOptions.resveKind = "ALL";
      this.searchOptions.resveChannel = "ALL";
      this.searchOptions.roomType = "ALL";
      this.searchOptions.memberDiv = "ALL";
      this.searchOptions.memberGrade = "ALL";
      this.searchOptions.roomSaleKind = "ALL";
      this.searchOptions.roomDcKind = "ALL";
      this.searchOptions.isGolfPackage = false;
    },
    onSearchDetailPopupClose() {
      this.isSearchDetailPopupOpen = false;
    },
    async onSaveButtonClicked() {
      if (!this.isReservationDetailChanged()) {
        this.errorToast("수정된 데이터가 없습니다");
        return;
      }

      if (!this.validateFormRefs(this.roomReservationInfoValidationRules)) {
        return;
      }

      const grid = this.$refs.roomResveDetailGrid;
      if (!grid.validate()) {
        return;
      }

      const records = grid.getBatchCurrentViewRecords();

      if (!(records.length > 0)) {
        this.errorToast("객실이 존재하지 않습니다");
        return;
      }

      // if (records.filter(item => item.reprsntRoomFlag && item.rresveStatus === "CANCEL").length > 0) {
      //   this.errorToast("취소 객실은 대표 객실이 될 수 없습니다");
      //   return;
      // } else if (!(records.filter(item => item.reprsntRoomFlag).length > 0)) {
      //   this.errorToast("대표 객실이 존재하지 않습니다");
      //   return;
      // } else if (records.filter(item => item.reprsntRoomFlag).length > 1) {
      //   this.errorToast("대표 객실은 반드시 1건만 선택되어야 합니다");
      //   return;
      // }

      const resveMember =
        this.roomReservationDetail.reservationInfo.rresveNo ?
          deepDiffs(this.orgRoomReservationDetail.reservationInfo, this.roomReservationDetail.reservationInfo) :
          this.roomReservationDetail.reservationInfo
      ;

      if (resveMember) {
        resveMember.rresveNo = this.roomReservationDetail.reservationInfo.rresveNo;
        if (
          !!resveMember.rresveNo &&
          !!resveMember.memberDiv &&
          resveMember.memberDiv === "NOM"
        ) {
          resveMember.membershipId = "";
          resveMember.memberNo = "";
        }
      }

      const {
        addedRecords,
        changedRecords,
      } = grid.patchBatchChanges();
      const resveStays = [];
      addedRecords.forEach(item => {
        resveStays.push(item);
      });
      changedRecords.forEach(item => {
        resveStays.push(({
          ...item,
          stayId: records.find(record => record._rid === item._rid)?.stayId,
        }));
      });

      const rresveNo = await GolfErpAPI.roomReservationDetailSave({
        resveMember: resveMember,
        resveStays: resveStays,
        rresveNo: this.roomReservationDetail.reservationInfo.rresveNo ? this.roomReservationDetail.reservationInfo.rresveNo : null,
        resveNo: this.roomReservationDetail.reservationInfo?.resveNo,
      });

      this.infoToast("저장되었습니다");

      // 자동 SMS 발송 보류.
      // if (addedRecords.length > 0) {
      //   if (await this.confirm("예약 SMS를 발송하시겠습니까?")) {
      //     await this.autoSendSms(addedRecords, rresveNo);
      //   }
      // }

      // // 객실 예약 상세를 수정한 내용이 있을때만.
      // if (resveStays.length > 0 && resveStays.filter(item => item.arrivalDate).length > 0) {
      //   const dateFrom = _maxBy(resveStays.map(item => item.arrivalDate));
      //   if (!(this.searchOptions.arrivalDateRangeValue.from <= dateFrom && this.searchOptions.arrivalDateRangeValue.to >= dateFrom)) {
      //     // 현재 입실일자 조회조건과 안맞을때 from 셋팅.
      //     this.searchOptions.arrivalDateRangeValue.from = dateFrom;
      //
      //     if (this.searchOptions.arrivalDateRangeValue.to < dateFrom) {
      //       // 현재 입실일자 조회조건과 안맞을때 to 셋팅.
      //       this.searchOptions.arrivalDateRangeValue.to = dateFrom;
      //     }
      //   }
      // }

      this.isRoomReservationSaveButtonClicked = true;
      this.isQuestionReservationDetailModify = true;

      await this.getRoomReservationDetail(rresveNo);
      await this.viewButtonClicked();

      const selectedRecords = grid.getSelectedRecords();
      if (selectedRecords.length > 0 && selectedRecords[0].stayId) {
        await this.setStayId(selectedRecords[0].stayId);
      }
    },
    setSendSmsData(rresveNo, record) {
      const roomReservationDetailReservationInfo = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationInfo));
      const roomReservationDetails = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationDetails));
      return new Promise(function (resolve) {
        let smsSendList = [];
        let sendData = {};

        sendData.sendKey = rresveNo;
        sendData.name =
          roomReservationDetailReservationInfo.resveName +
          " " +
          commonCodesGetCommonCodeAttrbNameByCodeAndIdx(
            "MEMBER_DIV",
            roomReservationDetailReservationInfo.memberDiv,
            1
          )
        ;
        sendData.resveNo = rresveNo;
        if (record) {
          sendData.roomType = commonCodesGetComName("ROOM_TYPE", record.roomType);
          sendData.resveDate = record.arrivalDate;
          sendData.dwName = getDayOfWeekCaption(record.arrivalDate);
          sendData.night = record.stayCnt;
        }

        const tsConfAccounts = commonCodesGetStandardInfo("tsConfAccounts").find(item => item.accountUseDiv === "DPMNY");
        const packageGolf =
          Object.entries(
            _groupBy(
              _orderBy(
                roomReservationDetailReservationInfo?.trResveGroupMaps.map(trResveGroupMap =>
                  trResveGroupMap?.tgResveConfirms.map(tgResveConfirm => {
                    return {
                      resveDateByYYMMDD: getFormattedDate(tgResveConfirm.reservationTime.resveDate, "YY.MM.DD"),
                      dwName: commonCodesGetComName("DW_CODE", tgResveConfirm.reservationTime.dwCode),
                      resveCourse: tgResveConfirm.reservationTime.resveCourse,
                      resveCourseSortNo: commonCodesGetSortNo("COURSE_CODE", tgResveConfirm.reservationTime.resveCourse),
                      resveTime: tgResveConfirm.reservationTime.resveTime
                    };
                  })
                ).flat(),
                ["resveDateByYYMMDD", "resveCourseSortNo", "resveTime"]
              ),
              "resveDateByYYMMDD"
            )
          );
        sendData.packageGolf =
          (packageGolf[0] ?
            "1일차 : " +
            packageGolf[0][0] + "(" + packageGolf[0][1][0].dwName + ") " +
            Object.entries(_groupBy(packageGolf[0][1], "resveCourse"))
              .map(([key, value]) =>
                commonCodesGetComName("COURSE_CODE", key) + " " + value.map(item => item.resveTime).join(", ")
              ).join(", ") +
            " (" + packageGolf[0][1].length + "팀)" + (packageGolf[1] ? "\n" : "") : "") +
          (packageGolf[1] ?
            "2일차 : " +
            packageGolf[1][0] + "(" + packageGolf[1][1][0].dwName + ") " +
            Object.entries(_groupBy(packageGolf[1][1], "resveCourse"))
              .map(([key, value]) =>
                commonCodesGetComName("COURSE_CODE", key) + " " + value.map(item => item.resveTime).join(", ")
              ).join(", ") +
            " (" + packageGolf[1][1].length + "팀)" : "")
        ;
        const arrivalDateByMin = _minBy(roomReservationDetails.map(item => item.arrivalDate));
        sendData.packageRoom =
          "입실일자 : " + getFormattedDate(arrivalDateByMin, "YY-MM-DD") + "(" + getDayOfWeekCaption(arrivalDateByMin) + ")\n" +
          "예약객실 : " +
          Object.entries(_groupBy(roomReservationDetails, "roomType"))
            .map(([key, value]) =>
              commonCodesGetComName("ROOM_TYPE", key) + "/" +
              value.length + "실/" +
              _maxBy(value.map(item => item.stayCnt)) + "박"
            ).join(", ")
        ;
        sendData.bankName = commonCodesGetComName("BANK_CODE", tsConfAccounts.bankCode);
        sendData.acnutNo = tsConfAccounts.acnutNo;

        const smsRecptnDiv = roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.smsRecptnDiv || "Y";

        if (smsRecptnDiv === "A") {
          if (roomReservationDetailReservationInfo.contactTel) {
            sendData.receiverName = roomReservationDetailReservationInfo.resveName;
            sendData.contactTel = roomReservationDetailReservationInfo.contactTel;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }

          if (roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.resveMngrHp) {
            sendData.receiverName = roomReservationDetailReservationInfo.resveName;
            sendData.contactTel = roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.resveMngrHp;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else if (smsRecptnDiv === "B") {
          if (roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.resveMngrHp) {
            sendData.receiverName = roomReservationDetailReservationInfo.resveName;
            sendData.contactTel = roomReservationDetailReservationInfo?.tgMembership?.memberInfo?.resveMngrHp;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        } else {
          if (roomReservationDetailReservationInfo.contactTel) {
            sendData.receiverName = roomReservationDetailReservationInfo.resveName;
            sendData.contactTel = roomReservationDetailReservationInfo.contactTel;
            sendData.smsSendFlag = true;

            smsSendList.push(JSON.parse(JSON.stringify(sendData)));
          }
        }

        resolve(smsSendList);
      });
    },
    async autoSendSms(records, rresveNo) {
      let smsSendList = [];
      await this.setSendSmsData(rresveNo)
        .then((response) => {
          smsSendList = response;
        })
        .catch((error) => {
          console.log("setSendSmsData.===>", error);
        });

      // SMS 전송할 데이터 없으면 RETURN.
      if (!(smsSendList.length > 0)) {
        this.errorToast("SMS 전송할 연락처가 존재하지 않습니다");
        return;
      }

      const smsSendInfo = {
        type: "R_RESVE",
        bizName: commonCodesGetStandardInfo("bizName"),
        bizNameLocation: commonCodesGetStandardInfo("bizNameLocation"),
        kakaoSendFlag: false,
        smsKind: null,
        kakaoDispatchKey: commonCodesGetStandardInfo("kakaoDsptchKey"),
        kakaoTemplateCode: null,
        subject: null,
      };

      const smsWords = await GolfErpAPI.fetchAllSmsWords();

      const smsSendType = smsWords.find(
        (data) => data.smsSendType === smsSendInfo.type
      );

      let message =
        (smsSendType.smsWords != null ? smsSendType.smsWords : "") +
        (smsSendType.aditWords != null ? "\n" + smsSendType.aditWords : "");

      if (smsSendInfo.bizNameLocation === "DOWN") {
        message =
          message +
          (smsSendInfo.bizName != null ? "\n" + smsSendInfo.bizName : "");
      }

      smsSendInfo.kakaoSendFlag = smsSendType.kakaoSendFlag;
      smsSendInfo.kakaoTemplateCode = smsSendType.kakaoTmplatCode;
      smsSendInfo.smsKind = smsSendType.smsKind;
      smsSendInfo.subject = smsSendType.smsSendTypeName;

      let smsRequestList = [];
      smsSendList.forEach((data) => {
        records.forEach(record => {
          let smsInfo = {
            type: smsSendInfo.type,
            kakaoFlag: smsSendInfo.kakaoSendFlag,
            smsKind: smsSendInfo.kakaoSendFlag ? smsSendInfo.smsKind : null,
            kakaoDispatchKey: smsSendInfo.kakaoSendFlag
              ? smsSendInfo.kakaoDispatchKey
              : null,
            kakaoTemplateKey: smsSendInfo.kakaoSendFlag
              ? smsSendInfo.kakaoTemplateCode
              : null,
            key: data.sendKey,
            reservedFlag: false,
            reserveDateTime: null,
            receiverName: data.receiverName,
            receiverNumber: data.contactTel,
            subject: smsSendInfo.subject,
            message: message,
            replaceDataMap: {
              name: data.name,
              roomType: commonCodesGetComName("ROOM_TYPE", record.roomType),
              resveDate: record.arrivalDate,
              dwName: getDayOfWeekCaption(record.arrivalDate),
              night: record.stayCnt,
              resveNo: rresveNo,
              headerMemo: smsSendInfo.bizNameLocation === "UP" ? (smsSendInfo.bizName ? smsSendInfo.bizName : "") : ""
            },
            dispatchNumber: smsSendType.dsptchNo,
            sender: "ERP",
          };

          smsRequestList.push(smsInfo);
        });
      });

      await GolfErpAPI.sendSMS(smsRequestList);
    },
    onCancelButtonClicked() {
      if (!this.roomReservationDetail.reservationInfo.rresveNo) {
        this.errorToast("예약번호가 존재하지 않습니다");
        return;
      }

      if (
        this.roomReservationDetail.reservationDetails
          .filter(item => commonCodesGetCommonCodeAttrbByCodeAndIdx("RRESVE_STATUS", item.rresveStatus, 1) === "STAY").length > 0
      ) {
        this.errorToast("체크인 또는 체크아웃 상태의 객실이 존재하여 예약 취소가 불가능합니다");
        return;
      }

      this.isRoomReservationCancelPopup = true;

      this.$nextTick(() => {
        this.$refs.roomReservationCancelPopup.showPopup({
          rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
        });
      });
    },
    async sendSMS() {
      if (!this.roomReservationDetail.reservationInfo.rresveNo) {
        this.errorToast("SMS 전송할 자료가 존재하지 않습니다");
        return;
      }

      const grid = this.$refs.roomResveDetailGrid;
      const selectedRecords = grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("SMS 전송할 객실 예약 정보를 선택해 주시기 바랍니다");
        return;
      }

      // if (selectedRecords.filter(item => item.rresveStatus !== "RESVE").length > 0) {
      //   this.errorToast("예약상태 [예약]만 SMS 전송이 가능합니다");
      //   return;
      // }

      let smsSendList = [];
      await this.setSendSmsData(this.roomReservationDetail.reservationInfo.rresveNo, selectedRecords[0])
        .then((response) => {
          smsSendList = response;
        })
        .catch((error) => {
          console.log("setSendSmsData.===>", error);
        });

      if (!(smsSendList.length > 0)) {
        this.errorToast("SMS 전송할 연락처가 존재하지 않습니다");
        return;
      }

      console.log('smsSendList.===>', smsSendList);

      this.isReservationSmsSendPopup = true;
      this.$nextTick(() => {
        this.$refs.reservationSmsSendPopup.showReservationSmsSendPopup(
          smsSendList,
          "P_RESVE_WAIT",
          true,
          true,
          true,
          ["R_RESVE", "R_WAIT_RESVE", "P_RESVE_WAIT", "P_RESVE", "P_CNFIRM"],
        );
      });
    },
    async onChangeHistoryButtonClicked() {
      const selectedRecords = this.$refs.roomResveDetailGrid.getSelectedRecords();

      if (selectedRecords.length > 0 && selectedRecords[0].stayId) {
        const {
          stayId,
        } = selectedRecords[0];

        const rawHistories = await GolfErpAPI.fetchRoomReservationHistory(stayId);

        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              TrResveStayPaymt: {
                __name__: "객실 객실료 상세",
                salesDate: {
                  name: "매출일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                salePrice: {
                  name: "판매단가",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                priceDiv: {
                  name: "단가구분",
                  type: "commonCode",
                  groupCode: "PRICE_DIV",
                  methods: ["create", "update", "delete"],
                },
                dcAmt: {
                  name: "할인금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                dcRate: {
                  name: "할인율",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                notaxCode: {
                  name: "면세코드",
                  type: "commonCode",
                  groupCode: "NOTAX_CODE",
                  methods: ["create", "update", "delete"],
                },
                roomAmt: {
                  name: "객실금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                dcContents: {
                  name: "할인내용",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                salesOccurFlag: {
                  name: "매출발생여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                delFlag: {
                  name: "삭제여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
              },
              TrResveGroupMap: {
                __name__: "객실 그룹 예약 맵핑",
                rresveNo: {
                  name: "객실예약번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveNo: {
                  name: "골프예약번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                delFlag: {
                  name: "삭제여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                delReason: {
                  name: "삭제사유",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveDate: {
                  name: "골프예약일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveTime: {
                  name: "골프예약시간",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveCourse: {
                  name: "골프예약코스",
                  type: "commonCode",
                  groupCode: "COURSE_CODE",
                  methods: ["create", "update", "delete"],
                },
                resveName: {
                  name: "골프예약자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              TrResveMember: {
                __name__: "객실 예약 정보",
                rresveNo: {
                  name: "객실예약번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveKind: {
                  name: "예약종류",
                  type: "commonCode",
                  groupCode: "RESVE_KIND",
                  methods: ["create", "update", "delete"],
                },
                resveChannel: {
                  name: "예약채널",
                  type: "commonCode",
                  groupCode: "RESVE_CHANNEL",
                  methods: ["create", "update", "delete"],
                },
                resveDate: {
                  name: "예약일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                groupName: {
                  name: "그룹명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveName: {
                  name: "예약자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipId: {
                  name: "회원권ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberNo: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberDiv: {
                  name: "회원구분",
                  type: "commonCode",
                  groupCode: "MEMBER_DIV",
                  methods: ["create", "update", "delete"],
                },
                memberGrade: {
                  name: "회원등급",
                  type: "commonCode",
                  groupCode: "MEMBER_GRADE",
                  methods: ["create", "update", "delete"],
                },
                sexCode: {
                  name: "성별코드",
                  type: "commonCode",
                  groupCode: "SEX_CODE",
                  methods: ["create", "update", "delete"],
                },
                areaCode: {
                  name: "지역코드",
                  type: "commonCode",
                  groupCode: "AREA_CODE",
                  methods: ["create", "update", "delete"],
                },
                contactName: {
                  name: "연락자명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                contactTel: {
                  name: "연락처",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                grpKind: {
                  name: "단체종류",
                  type: "commonCode",
                  groupCode: "GRP_KIND",
                  methods: ["create", "update", "delete"],
                },
                grpNo: {
                  name: "단체번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                grpName: {
                  name: "단체명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                resveRemarks: {
                  name: "예약비고",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                frontMemo: {
                  name: "프론트메모",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                vipFlag: {
                  name: "VIP여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                smsSendFlag: {
                  name: "SMS전송여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                smsSendCnt: {
                  name: "SMS전송횟수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                smsSendDt: {
                  name: "SMS전송일시",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              TrResveStay: {
                __name__: "객실 예약 상세",
                rresveNo: {
                  name: "객실예약번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                rresveStatus: {
                  name: "객실예약상태",
                  type: "commonCode",
                  groupCode: "RRESVE_STATUS",
                  methods: ["create", "update", "delete"],
                },
                guestName: {
                  name: "투숙객명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                arrivalDate: {
                  name: "입실일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                stayCnt: {
                  name: "숙박수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                departureDate: {
                  name: "퇴실일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                roomType: {
                  name: "객실타입",
                  type: "commonCode",
                  groupCode: "ROOM_TYPE",
                  methods: ["create", "update", "delete"],
                },
                roomId: {
                  name: "객실ID",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                roomNo: {
                  name: "객실번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                reprsntRoomFlag: {
                  name: "대표객실여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                frpyAbleFlag: {
                  name: "후불가능여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                nationCode: {
                  name: "국가코드",
                  type: "commonCode",
                  groupCode: "NATION_CODE",
                  methods: ["create", "update", "delete"],
                },
                adultCnt: {
                  name: "성인인원수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                childCnt: {
                  name: "소아인원수",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                promtnName: {
                  name: "프로모션",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                roomSaleKind: {
                  name: "객실판매유형",
                  type: "commonCode",
                  groupCode: "ROOM_SALE_KIND",
                  methods: ["create", "update", "delete"],
                },
                roomDcKind: {
                  name: "객실할인유형",
                  type: "commonCode",
                  groupCode: "ROOM_DC_KIND",
                  methods: ["create", "update", "delete"],
                },
                dcContents: {
                  name: "할인내용",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                vehicleNo: {
                  name: "차량번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                contactTel: {
                  name: "연락처",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                autoChkinFlag: {
                  name: "자동체크인여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                chkinDt: {
                  name: "체크인일시",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                chkoutDt: {
                  name: "체크아웃일시",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                roomCardkeyReturnFlag: {
                  name: "객실카드키반납여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                // chkinSmsSendFlag: {
                //   name: "체크인SMS전송여부",
                //   type: "boolean",
                //   methods: ["create", "update", "delete"],
                // },
                // chkinSmsSendCnt: {
                //   name: "체크인SMS전송횟수",
                //   type: "number",
                //   methods: ["create", "update", "delete"],
                // },
                // chkinSmsSendDt: {
                //   name: "체크인SMS전송일시",
                //   type: "string",
                //   methods: ["create", "update", "delete"],
                // },
                cancelDiv: {
                  name: "취소구분",
                  type: "commonCode",
                  groupCode: "CANCEL_DIV",
                  methods: ["create", "update", "delete"],
                },
                // cancelChannel: {
                //   name: "취소채널",
                //   type: "commonCode",
                //   groupCode: "CANCEL_CHANNEL",
                //   methods: ["create", "update", "delete"],
                // },
                cancelDate: {
                  name: "취소일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                // cancelDaycnt: {
                //   name: "취소일수",
                //   type: "number",
                //   methods: ["create", "update", "delete"],
                // },
                cancelResn: {
                  name: "취소사유",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
            },
          });
        });
      } else {
        this.errorToast("객실 예약정보가 없습니다");
      }
    },
    historyPopupClose() {
      this.isHistoryPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    async getRoomReservationDetail(rresveNo) {
      const data = await GolfErpAPI.fetchRoomReservationDetail({
        rresveNo: rresveNo
      });

      this.roomReservationDetail.reservationInfo = data;
      this.roomReservationDetail.reservationDetails =
        data.trResveStays ?
          _orderBy(
            data.trResveStays.filter(item => this.searchOptions.isExcludResveCancel ? item.rresveStatus !== "CANCEL" : true).map(item => ({
              ...item,
              roomAmt: item?.trResveStayPaymts?.map(item => item.roomAmt).reduce((acc, cur) => acc + cur, 0),
              isNewMemo: (item?.memoReceivedCount || 0) > (item?.memoConfirmCount || 0),
            })), ["arrivalDate", "departureDate", "guestName"]) :
          []
      ;
      this.roomReservationDetail.reservationDetailsCount = (data.trResveStays ? data.trResveStays : []).length;
      this.orgRoomReservationDetail.reservationInfo = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationInfo));

      this.isRoomResveDetailViewOpened = true;

      if (!(this.roomReservationDetail.reservationDetails.length > 0)) {
        this.onRoomReservationDetailViewClosed();
      }
    },
    isReservationDetailChanged() {
      // 수정중인 항목을 반영하기 위함(saveCell)
      this.$refs.roomResveDetailGrid.saveCell();

      const {
        addedRecords,
        changedRecords
      } = this.$refs.roomResveDetailGrid.getBatchChanges();

      const orgData = JSON.parse(JSON.stringify(this.orgRoomReservationDetail.reservationInfo));
      const modifyData = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationInfo));

      return JSON.stringify(orgData) !== JSON.stringify(modifyData) || addedRecords.length > 0 || changedRecords.length > 0;
    },
    async onRoomReservationDetailViewClosed(isConfirm = true) {
      if (this.isReservationDetailChanged()) {
        if (isConfirm) {
          if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }
      }

      this.roomReservationDetailInit();
      this.isRoomResveDetailViewOpened = false;
    },
    roomReservationDetailInit() {
      this.orgRoomReservationDetail.reservationInfo = {
        rresveNo: null,
        resveDate: null,
        groupName: null,
        grpNo: null,
        grpName: null,
        grpKind: null,
        resveName: null,
        membershipId: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactName: null,
        contactTel: null,
        resveKind: null,
        resveChannel: null,
        areaCode: null,
        sexCode: null,
        resveRemarks: null,
        frontMemo: null,
        vipFlag: null,
        smsSendFlag: null,
        smsSendCnt: null,
        smsSendDt: null,
        insertName: null,
        insertDt: null,
        trResveGroupMaps: [],
      };
      this.roomReservationDetail.reservationInfo = {
        rresveNo: null,
        resveDate: null,
        groupName: null,
        grpNo: null,
        grpName: null,
        grpKind: null,
        resveName: null,
        membershipId: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactName: null,
        contactTel: null,
        resveKind: null,
        resveChannel: null,
        areaCode: null,
        sexCode: null,
        resveRemarks: null,
        frontMemo: null,
        vipFlag: null,
        smsSendFlag: null,
        smsSendCnt: null,
        smsSendDt: null,
        insertName: null,
        insertDt: null,
        trResveGroupMaps: [],
      };
      this.roomReservationDetail.reservationDetails = [];
      this.roomReservationDetail.reservationDetailsCount = 0;

      // data 초기화후 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.groupName.setEmpty();
      this.$refs.grpName.setEmpty();
      this.$refs.resveName.setEmpty();
    },
    async onGrpNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        console.log(
          "## 단체명을 바꾼 후 Focus out. 단체 조회 후 결과에 따라 단체 팝업을 띄운다. 단체 검색어 :" +
          args.value
        );
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 단체로 설정, 2건 이상이면 단체 팝업을 띄운다')
        // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
        // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
        //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
        //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
        //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
        // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
        //      : 단체번호, 단체종류, 단체명 Clear
        //      : 나머지는 그대로 놔둠
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.clearGrpInfo();
          return;
        }
        await getGroupList({ searchValue: args.value })
          .then((response) => {
            const groupList = response.value.groupInfoList;
            if (groupList && groupList.length > 1) {
              this.onGroupPopupOpen();
            } else if (groupList && groupList.length === 1) {
              // 그룹정보 설정
              this.setGrpInfos(groupList[0]);
              this.reservationCheck(null, groupList[0].grpNo, null); // 위약 체크
            } else {
              this.onGroupPopupOpen(false, "TEMP");
            }
          })
          .catch((error) => {
            console.log("getGroupList.err.===>", error);
          });
      }
    },
    clearGrpInfo() {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      if (this.roomReservationDetail.reservationInfo) {
        this.roomReservationDetail.reservationInfo.grpNo = "";
        this.roomReservationDetail.reservationInfo.grpKind = "FIT";
        this.roomReservationDetail.reservationInfo.grpName = "";
      }
    },
    setGrpInfos(grpInfo) {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      if (this.roomReservationDetail.reservationInfo) {
        this.roomReservationDetail.reservationInfo.grpNo = grpInfo.grpNo;
        this.roomReservationDetail.reservationInfo.grpKind = grpInfo.grpKind;
        this.roomReservationDetail.reservationInfo.grpName = grpInfo.grpName;
        this.roomReservationDetail.reservationInfo.resveChannel = grpInfo.resveChannel;
        if (grpInfo.grpKind !== "TEMP") {
          this.roomReservationDetail.reservationInfo.resveName =
            grpInfo.grpName;
          if (grpInfo.generName) {
            this.roomReservationDetail.reservationInfo.contactName =
              grpInfo.generName;
          } else {
            this.roomReservationDetail.reservationInfo.contactName = null;
          }
          if (grpInfo.generContactTel) {
            this.roomReservationDetail.reservationInfo.contactTel =
              grpInfo.generContactTel;
          } else {
            this.roomReservationDetail.reservationInfo.contactTel = null;
          }
          this.roomReservationDetail.reservationInfo.membershipId = null;
          this.roomReservationDetail.reservationInfo.memberNo = null;
          this.roomReservationDetail.reservationInfo.sexCode = "M";
          this.roomReservationDetail.reservationInfo.memberDiv = "NOM";
          this.roomReservationDetail.reservationInfo.memberGrade = commonCodesGetJsonData(
            "MEMBER_DIV",
            "NOM"
          ).find((codeJson) => codeJson.IDX === 4).ATTRB;
        }
      }
    },
    onGroupPopupOpen(isOpenedWithButton = false, grpKind) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.roomReservationDetail.reservationInfo
        .grpName
        ? (groupPopupData.grpName = this.roomReservationDetail.reservationInfo.grpName)
        : null;
      groupPopupData.resveDates =
        [
          _minBy(this.roomReservationDetail.reservationDetails.map(item => item.arrivalDate)) || this.searchOptions.stayDate,
          _maxBy(this.roomReservationDetail.reservationDetails.map(item => item.departureDate)) || this.searchOptions.stayDate
        ];
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      groupPopupData.grpKind = grpKind;
      if (grpKind) {
        groupPopupData.grpName = null;
        groupPopupData.tempGrpName = this.roomReservationDetail.reservationInfo.grpName;
      }
      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
      // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
      //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
      //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
      //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
      // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
      //      : 단체번호, 단체종류, 단체명 Clear
      //      : 나머지는 그대로 놔둠
      // console.log(JSON.stringify(event, null, 2))
      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
          // this.reservationCheck(null, event.selectedGroup.grpNo, null); // 위약 체크
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
          // this.reservationCheck(null, event.selectedGroup.grpNo, null); // 위약 체크
        } else {
          this.clearGrpInfo();
        }
      }
    },
    onGroupNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        this.$EventBus.$emit("loaderOn");
        try {
          // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
          // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

          // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
          // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
          // #2. 회원/비회원 위임자의 경우
          // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명, 지역을 예약자명과 동일하게 설정
          // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
          //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
          //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
          if (args.value.trim() === "") {
            // 빈 칸인 경우에는 미 설정으로
            if (!this.roomReservationDetail.reservationInfo.resveName) {
              this.setResvNonMemberInfos();
            }
            return;
          }

          // if (!this.roomReservationDetail.reservationInfo.resveName) {
          //   this.setResvNonMemberInfos();
          // }

          // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.GROUP_NAME,
            args.value,
            null
          );
        } catch (error) {
          console.log("getMemberList.err.===>", error);
        } finally {
          this.$EventBus.$emit("loaderOff");
        }
      }
    },
    onResveNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        this.$EventBus.$emit("loaderOn");
        try {
          // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
          // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

          // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
          // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
          // #2. 회원/비회원 위임자의 경우
          // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명, 지역을 예약자명과 동일하게 설정
          // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
          //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
          //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
          if (args.value.trim() === "") {
            // 빈 칸인 경우에는 미 설정으로
            this.setResvNonMemberInfos();
            return;
          }

          this.setResvNonMemberInfos();

          // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            args.value,
            null
          );
        } catch (error) {
          console.log("getMemberList.err.===>", error);
        } finally {
          this.$EventBus.$emit("loaderOff");
        }
      }
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx,
      memberDivChanged = false,
      memberGradeChanged = false
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    async memberSelectPopupClosed(data) {
      console.log(JSON.stringify(data, null, 2));
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.popupData.memberDivChanged) {
          let commonCodeJsonDataStr = this.roomReservationViewOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode ===
              this.roomReservationDetail.reservationInfo.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                // 회원구분 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
                // 회원구분 변경한 값은 그대로 유지.
                // MEMBERSHIP_ID, MEMBER_NO = 기존값 그대로 유지.
                // MEMBER_DIV = 변경한 값
                // MEMBER_GRADE = 회원구분 변경에 따른 값 셋팅.
                this.roomReservationDetail.reservationInfo.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else if (data.popupData.memberGradeChanged) {
          // 회원등급 변경시 동명이인 회원 검색 팝업 호출후 팝업 그냥 닫으면
          // 회원등급 변경한 값은 그대로 유지.
          // MEMBERSHIP_ID, MEMBER_NO, MEMBER_DIV = 기존값 그대로 유지.
          // MEMBER_GRADE = 변경한 값
        } else {
          // const response = await getReservationInfoTable(
          //   this.reservationOptions.resveDate,
          //   this.reservationOptions.resveDate,
          //   null,
          //   null,
          //   this.reservationDetailInfo.reservationInfo.resveName,
          //   null,
          //   null,
          //   null
          // );
          //
          // if (response?.value?.reservationInfoLists && response?.value?.reservationInfoLists.length > 0) {
          //   this.infoToast("해당일자에 같은 예약자명이 있습니다");
          // }
          if (!data.popupData.isOpenedWithButton) {
            // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
            this.setResvNonMemberInfos();
          }
        }
      } else if (data.popupData.popupType === this.memberPopupType.GROUP_NAME) {
        if (!data.popupData.isOpenedWithButton) {
          // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
          if (!this.roomReservationDetail.reservationInfo.resveName) {
            this.setResvNonMemberInfos();
          }
        }
      }
    },
    async memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
      if (data.popupData.popupType === this.memberPopupType.RESV) {
        // 예약자명
        if (data.selectedRowData) {
          if (!data.selectedRowData.membershipId) {
            if (data.selectedRowData.memberName) {
              this.roomReservationDetail.reservationInfo.resveName = data.selectedRowData.memberName;
            }
            this.setResvNonMemberInfos();
          } else {
            this.setResvMemberInfos(data.selectedRowData);

            // // 위약 체크
            // this.reservationCheck(
            //   null,
            //   null,
            //   data.selectedRowData.membershipId
            // );
          }

          // const response = await getReservationInfoTable(
          //   this.reservationOptions.resveDate,
          //   this.reservationOptions.resveDate,
          //   null,
          //   null,
          //   data.selectedRowData.memberName,
          //   null,
          //   null,
          //   null
          // );
          //
          // if (response?.value?.reservationInfoLists && response?.value?.reservationInfoLists.length > 0) {
          //   this.infoToast("해당일자에 같은 예약자명이 있습니다");
          // }

          await this.$nextTick();
          this.$refs.resveName.focusIn();
        }
      } else if (data.popupData.popupType === this.memberPopupType.GROUP_NAME) {
        if (data.selectedRowData) {
          if (!data.selectedRowData.membershipId) {
            if (data.selectedRowData.memberName) {
              this.roomReservationDetail.reservationInfo.groupName = data.selectedRowData.memberName;
            }
            if (!this.roomReservationDetail.reservationInfo.resveName) {
              this.setResvNonMemberInfos();
            }
          } else {
            this.roomReservationDetail.reservationInfo.groupName = data.selectedRowData.memberName;
            if (!this.roomReservationDetail.reservationInfo.resveName) {
              this.setResvMemberInfos(data.selectedRowData);
            }

            // // 위약 체크
            // this.reservationCheck(
            //   null,
            //   null,
            //   data.selectedRowData.membershipId
            // );
          }

          // const response = await getReservationInfoTable(
          //   this.reservationOptions.resveDate,
          //   this.reservationOptions.resveDate,
          //   null,
          //   null,
          //   data.selectedRowData.memberName,
          //   null,
          //   null,
          //   null
          // );
          //
          // if (response?.value?.reservationInfoLists && response?.value?.reservationInfoLists.length > 0) {
          //   this.infoToast("해당일자에 같은 예약자명이 있습니다");
          // }

          await this.$nextTick();
          this.$refs.groupName.focusIn();
        }
      }
    },
    setResvNonMemberInfos() {
      // // 연계회원 clear
      // this.memberLinkList = [];

      if (this.roomReservationDetail.reservationInfo) {
        this.roomReservationDetail.reservationInfo.resveName = this.roomReservationDetail.reservationInfo.resveName || this.roomReservationDetail.reservationInfo.groupName;
        this.roomReservationDetail.reservationInfo.membershipId = null;
        this.roomReservationDetail.reservationInfo.memberNo = null;
        this.roomReservationDetail.reservationInfo.sexCode = "M";
        this.roomReservationDetail.reservationInfo.memberDiv = "NOM";
        this.roomReservationDetail.reservationInfo.memberGrade = commonCodesGetJsonData(
          "MEMBER_DIV",
          "NOM"
        ).find((codeJson) => codeJson.IDX === 4).ATTRB;
        this.roomReservationDetail.reservationInfo.contactName = this.roomReservationDetail.reservationInfo.resveName;
        this.roomReservationDetail.reservationInfo.areaCode = commonCodeGetComCodeDefaultValue("AREA_CODE");
      }

      const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();

      if (records.length > 0) {
        records.forEach((record, idx) => {
          if (!record.guestName) {
            this.$refs.roomResveDetailGrid.updateCell(idx, "guestName", this.roomReservationDetail.reservationInfo.resveName || this.roomReservationDetail.reservationInfo.groupName);
          }
        });
      }
    },
    setResvMemberInfos(memberInfo) {
      if (this.roomReservationDetail.reservationInfo) {
        this.roomReservationDetail.reservationInfo.resveName =
          memberInfo.memberName;
        this.roomReservationDetail.reservationInfo.membershipId =
          memberInfo.membershipId;
        this.roomReservationDetail.reservationInfo.memberNo =
          memberInfo.memberNo;
        this.roomReservationDetail.reservationInfo.memberDiv =
          memberInfo.memberDiv;
        this.roomReservationDetail.reservationInfo.memberGrade =
          memberInfo.memberGrade;
        this.roomReservationDetail.reservationInfo.sexCode = memberInfo.sexCode;
        if (this.commonMixinHasCiperTextGet) {
          // commonMixinHasCiperTextGet 권한이 있는 경우에만
          this.roomReservationDetail.reservationInfo.contactTel =
            memberInfo.hpNo;
        }
        this.roomReservationDetail.reservationInfo.contactName =
          memberInfo.memberName;
        this.roomReservationDetail.reservationInfo.areaCode =
          memberInfo.areaCode ? memberInfo.areaCode : commonCodeGetComCodeDefaultValue("AREA_CODE");
      }

      const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();

      if (records.length > 0) {
        records.forEach((record, idx) => {
          if (!record.guestName) {
            this.$refs.roomResveDetailGrid.updateCell(idx, "guestName", memberInfo.memberName);
          }
        });
      }
    },
    onReservationInfoMemberDivDropdownListChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        if (args.value === "NOM") {
          let commonCodeJsonDataStr = this.roomReservationViewOptions.memberDivOptions.find(
            (commonCode) =>
              commonCode.comCode ===
              this.roomReservationDetail.reservationInfo.memberDiv
          ).jsonData;
          if (commonCodeJsonDataStr) {
            let commonCodeJsonDataArray = JSON.parse(commonCodeJsonDataStr);
            if (Array.isArray(commonCodeJsonDataArray)) {
              let memberGradeDefaultValue = commonCodeJsonDataArray.find(
                (jsonData) => jsonData.IDX === 4
              );
              if (memberGradeDefaultValue && memberGradeDefaultValue.ATTRB) {
                this.roomReservationDetail.reservationInfo.membershipId = "";
                this.roomReservationDetail.reservationInfo.memberNo = "";
                this.roomReservationDetail.reservationInfo.memberGrade =
                  memberGradeDefaultValue.ATTRB;
              }
            }
          }
        } else {
          // 회원구분이 비회원이 아니면 동명이인 회원 검색 팝업 호출.
          this.onMemberPopupOpen(
            false,
            this.memberPopupType.RESV,
            this.roomReservationDetail.reservationInfo.resveName,
            null,
            null,
            true
          );
        }
      }
    },
    onContactTelInfoBlur() {
      const value = this.roomReservationDetail.reservationInfo.contactTel;
      if ((this.changeContactTel === null ? "" : this.changeContactTel) !== value) {
        // TODO : maskedTextBox의 위약 처리는 blur 이벤트에서 함(textbox와 달리, @change 이벤트에서 focus out 되는 상황을 잡아낼 수 없기 때문)
        // this.reservationCheck(value, null, null);
      }
      this.changeContactTel = value;
    },
    onResveKindChanged(args) {
      if (
        args.e instanceof MouseEvent ||
        args.e instanceof KeyboardEvent ||
        args.e instanceof TouchEvent
      ) {
        // this.reservationCheck(null, null, this.reservationDetailInfo.reservationInfo.membershipId, this.reservationDetailInfo.reservationInfo.resveNo);
      }
    },
    reservationInfoInit() {
      this.roomReservationDetail.reservationInfo = {
        rresveNo: null,
        resveDate: moment().format("YYYY-MM-DD"),
        groupName: null,
        grpNo: null,
        grpName: null,
        grpKind: commonCodeGetComCodeDefaultValue("GRP_KIND"),
        resveName: null,
        memberNo: null,
        memberDiv: null,
        memberGrade: null,
        contactName: null,
        contactTel: null,
        resveKind: commonCodeGetComCodeDefaultValue("RESVE_KIND"),
        resveChannel: commonCodeGetComCodeDefaultValue("RESVE_CHANNEL"),
        areaCode: null,
        sexCode: null,
        resveRemarks: null,
        frontMemo: null,
        vipFlag: false,
        smsSendFlag: false,
        smsSendCnt: 0,
        smsSendDt: null,
        insertName: null,
        insertDt: null,
        trResveGroupMaps: [],
      };
      this.orgRoomReservationDetail.reservationInfo = JSON.parse(JSON.stringify(this.roomReservationDetail.reservationInfo));
      this.roomReservationDetail.reservationDetails = [];
      this.$refs.roomResveDetailGrid.refresh();
    },
    onRoomDetailAddButtonClicked(event, args = {}) {
      if (!args?.arrivalDate) {
        const viewRecords = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();
        if (viewRecords.length > 0) {
          args.arrivalDate = viewRecords[viewRecords.length - 1].arrivalDate;
        } else {
          args.arrivalDate = this.searchOptions.stayDate;
        }
      }

      const addRecord = {
        reprsntRoomFlag: false,
        frpyAbleFlag: false,
        guestName: this.roomReservationDetail.reservationInfo.resveName,
        rresveStatus: "WAIT",
        arrivalDate: args?.arrivalDate || getTodayDate(),
        stayCnt: 1,
        departureDate: getDateOfNext(args?.arrivalDate || getTodayDate()),
        adultCnt: args.roomType ? commonCodesGetCommonCodeAttrbByCodeAndIdx("ROOM_TYPE", args.roomType, 1) || 0 : 0,
        childCnt: 0,
        roomSaleKind: commonCodeGetComCodeDefaultValue("ROOM_SALE_KIND"),
        roomDcKind: commonCodeGetComCodeDefaultValue("ROOM_DC_KIND"),
        roomType: args.roomType || null,
        roomNo: args.roomNo || null,
        roomId: args.roomId || null,
      };
      const _rid = this.$refs.roomResveDetailGrid.addRecord(addRecord);

      if (addRecord.roomType) {
        this.checkAvailableRooms(addRecord.roomType, addRecord.arrivalDate, addRecord.departureDate, null, this.$refs.roomResveDetailGrid, _rid);
      }
    },
    onRoomResveDetailGridDataBound() {
      const {toDate, fromDate, stayId} = this.$route.query || null;
      if (!this.roomReservationDetail.reservationInfo.rresveNo && !(toDate && fromDate && stayId)) {
        setTimeout(() => {
          this.$refs.groupName.focus();
        }, 500);
      }
      if (!this.roomReservationDetail.reservationInfo.rresveNo) {
        setTimeout(() => {
          this.$refs.groupName.focus();
        }, 500);
      }
    },
    onRoomResveDetailGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          },
        },
        node: {
          classList
        },
      } = args;

      if (
        [
          "reprsntRoomFlag",
          "guestName",
          "arrivalDate",
          "stayCnt",
          "departureDate",
          "roomType",
        ].includes(field)
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        classList.add(this.$t("className.grid.requiredInputHeader"));
      } else if (
        [
          "roomAmt"
        ].includes(field)
      ) {
        classList.add(this.$t("className.grid.clickArea"));
      } else if (field === "vacantRoomIcon") {
        classList.add(this.$t("className.grid.searchIconArea"));
      } else if (field === "memo") {
        classList.add(this.$t("className.grid.memoArea"));
      }
    },
    onRoomResveDetailGridQueryCellInfo(args) {
      const {
        column: {
          field,
          allowEditing,
        },
        cell: {
          classList,
          style,
        },
        data,
      } = args;

      if (["roomType", "roomSaleKind"].includes(field)) {
        args.cell.setAttribute("column-name", field);
      }

      if (data.rresveStatus === "CANCEL") {
        style.textDecoration = "line-through";
      }

      if (allowEditing) {
        classList.add(this.$t("className.grid.modifyArea"));
      }

      if (
        [
          "roomAmt",
        ].includes(field)
      ) {
        classList.add(this.$t("className.grid.clickArea"));
      } else if (field === "roomNo") {
        classList.add(this.$t("className.grid.clickAreaNone"));
      } else if (field === "vacantRoomIcon") {
        classList.add(this.$t("className.grid.searchIconArea"));
      } else if (field === "memo") {
        if (data.memoAllCount > 0) {
          if (data.isNewMemo && commonCodesGetStandardInfo("memoConfirmFlag")) {
            classList.add(this.$t("className.grid.newMemoArea"));
          } else {
            classList.add(this.$t("className.grid.memoArea"));
          }
        }
      } else if (field === "rresveStatus") {
        style.color = commonCodesGetColorValue("RRESVE_STATUS", data["rresveStatus"]);
      }
    },
    onRoomResveDetailGridCellSaved(args) {
      const {
        column: {
          field,
        },
        rowData,
        value,
        previousValue,
      } = args;

      const grid = this.$refs.roomResveDetailGrid;
      const rowIndex = grid.getRowIndexByPrimaryKey(rowData["_rid"]);

      if (["arrivalDate", "departureDate"].includes(field)) {
        if (!moment(value, "YYYY-MM-DD").isValid()) {
          this.errorToast("일자가 잘못되었습니다");
          grid.updateCell(rowIndex, field, previousValue);
          return;
        }

        const arrivalDate = (field === "arrivalDate" ? value : rowData["arrivalDate"]);
        const departureDate = (field === "departureDate" ? value : rowData["departureDate"]);

        if (arrivalDate > departureDate) {
          if (field === "departureDate") {
            this.errorToast("퇴실일자는 입실일자보다 이전일 수 없습니다");
            grid.updateCell(rowIndex, field, previousValue);
            return;
          } else {
            // 입실일자 변경시 박수로 퇴실일자를 재계산한다.
            const departureDateByStayCnt = moment(arrivalDate).add(rowData["stayCnt"], "days").format("YYYY-MM-DD");
            grid.updateCell(rowIndex, "departureDate", departureDateByStayCnt);
            if (value !== previousValue) {
              this.checkAvailableRooms(rowData.roomType, arrivalDate, departureDateByStayCnt, rowData.stayId, grid);
            }
            return;
          }
        }
        const stayCnt = moment(departureDate).diff(moment(arrivalDate), "days");
        grid.updateCell(rowIndex, "stayCnt", stayCnt);

        if (value !== previousValue) {
          this.checkAvailableRooms(rowData.roomType, arrivalDate, departureDate, rowData.stayId, grid);
        }
      } else if (field === "stayCnt") {
        const arrivalDate = rowData["arrivalDate"];
        const departureDate = moment(arrivalDate).add(value, "days").format("YYYY-MM-DD");
        grid.updateCell(rowIndex, "departureDate", departureDate);

        if (value !== previousValue) {
          this.checkAvailableRooms(rowData.roomType, arrivalDate, departureDate, rowData.stayId, grid);
        }
      }
    },
    onRoomResveDetailGridCellSave(args) {
      const {
        column: {
          field,
        },
      } = args;

      if (["roomType", "roomSaleKind"].includes(field)) {
        this.setEjsDropdonwlistEditTemplateValue(args);
      }
    },
    setEjsDropdonwlistEditTemplateValue(args) {
      args.value = args.cell.firstElementChild.getElementsByClassName(
        'e-dropdownlist',
      )[0].ej2_instances[0].itemData?.comCode;
    },
    async onRoomResveDetailGridRecordClick(args) {
      const {
        column: {
          field,
        },
        rowData,
      } = args;

      const grid = this.$refs.roomResveDetailGrid;
      // 수정중인 항목을 반영하기 위함(saveCell)
      grid.saveCell();

      if (field === "roomAmt") {
        if (this.isReservationDetailChanged()) {
          this.errorToast("수정된 데이터가 있습니다. 저장후 진행해주시기 바랍니다");
          return;
        }
        this.isRoomRateDetailsByDatePopup = true;
        this.$nextTick(() => {
          this.$refs.roomRateDetailsByDatePopup.showPopup({
            stayId: rowData["stayId"],
          });
        });
      } else if (field === "vacantRoomIcon") {
        const rowIndex = grid.getRowIndexByPrimaryKey(rowData["_rid"]);

        const currentViewRecords = grid.getBatchCurrentViewRecords();
        const records = currentViewRecords.filter(item => item._rid === rowData._rid);
        const {
          addedRecords,
          changedRecords,
        } = grid.patchBatchChanges();

        let roomIds = [];
        roomIds = roomIds.concat(
          addedRecords.filter(item => {
            const record = currentViewRecords.filter(record => record._rid === item._rid)[0] || {};
            return item.roomId &&
              record.arrivalDate <= records[0].arrivalDate &&
              (record.arrivalDate === record.departureDate ? true : record.departureDate >= records[0].arrivalDate);
          }).map(item => item.roomId)
        );
        roomIds = roomIds.concat(
          changedRecords.filter(item => {
            const record = currentViewRecords.filter(record => record._rid === item._rid)[0] || {};
            return item.roomId &&
              record.arrivalDate <= records[0].arrivalDate &&
              (record.arrivalDate === record.departureDate ? true : record.departureDate >= records[0].arrivalDate);
          }).map(item => item.roomId)
        );

        this.isVacantRoomPopup = true;
        this.$nextTick(() => {
          this.$refs.vacantRoomPopup.showPopup({
            arrivalDate: records[0].arrivalDate,
            departureDate: records[0].departureDate,
            roomType: records[0].roomType,
            stayId: rowData["stayId"],
            rowIndex: rowIndex,
            roomIds: roomIds,
          });
        });
      } else if (field === "roomNo") {
        const rowIndex = grid.getRowIndexByPrimaryKey(rowData["_rid"]);
        const {
          addedRecords,
        } = grid.patchBatchChanges();
        const addedRecord = addedRecords.find(item => item._rid === rowData["_rid"]);
        if (addedRecord) {
          if (addedRecord.roomId) {
            if (!(await this.confirm(`${addedRecord.roomNo}호 가배정을 취소하시겠습니까?`))) {
              return;
            }
            grid.updateCell(rowIndex, "roomId", null);
            grid.updateCell(rowIndex, "roomNo", null);
          }
        } else {
          if (rowData["roomId"]) {
            if (!(await this.confirm(`${rowData.roomNo}호 가배정을 취소하시겠습니까?`))) {
              return;
            }
            if (this.isReservationDetailChanged()) {
              if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
                return;
              }
            }
            await GolfErpAPI.roomReservationNamingCancel(rowData["stayId"]);

            await this.fetchRoomReservations();
            await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
          }
        }
      } else if (field === "memo") {
        await this.setStayId(rowData.stayId);
        this.$EventBus.$emit("memoShortcutButtonClick", true); // 메모창 열기
      }
    },
    async onRoomResveDetailGridRowSelected(args) {
      const { data } = args;
      this.clearSelectionBackground();

      if (data.stayId && data.stayId !== this.memoStayId) {
        await this.setStayId(data.stayId);
      } else if (!data.stayId) {
        await this.clearMemo();
      }
    },
    clearSelectionBackground() {
      Array.prototype.forEach.call(
          document.getElementsByClassName('e-cellselectionbackground'), (el) => el.classList.remove('e-cellselectionbackground')
      );
    },
    onRoomResveDetailGridActionComplete(args) {
      const {
        requestType,
        rows,
      } = args;
      if (requestType === "refresh") {
        if (rows?.length > 0) {
          const grid = this.$refs.roomResveDetailGrid;
          if (this.selectStayId) {
            // $nextTick : 실행되는 순서가 EjsGridWrapper.vue > onActionComplete 이벤트의 isAutoSelectCell 다음에 실행하게 하기 위함.
            this.$nextTick(() => {
              const {toDate, fromDate, stayId} = this.$route.query || null;
              if(toDate && fromDate && stayId) {
                const matchRecordRow = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords().find(record => record.stayId === Number(stayId)) || null;
                const cells = Array.prototype.map.call(this.$refs.roomResveDetailGrid.getDataRows(), ({cells}) => [...cells])[matchRecordRow?._rid - 1];
                if(cells) {
                  const cellIndex = Array.prototype.find.call(cells, (cell) => cell.innerText === matchRecordRow.guestName).getAttribute("aria-colindex") || 0;
                  cells[cellIndex].click();
                  this.$router.replace({query: null});
                }
              } else {
                const rid = rows.filter(item => item?.data?.stayId === this.selectStayId)[0]?.data?._rid || null;
                if (rid) {
                  const rowIndex = grid.getRowIndexByPrimaryKey(rid);
                  grid.selectRow(rowIndex);
                } else {
                  grid.selectRow(0);
                }
                this.selectStayId = null;
              }
            });
          } else {
            this.$nextTick(() => {
              grid.selectRow(0);
            });
          }
        }
      }
    },
    async onGridCheckboxChanged(args) {
      const {
        columnName,
        // rowData,
        // rowIndex,
        // value,
      } = args;

      if (columnName === "reprsntRoomFlag") {
        // if (value) {
        //   if (rowData.rresveStatus === "CANCEL") {
        //     await this.$nextTick();
        //     this.$refs.roomResveDetailGrid.saveCell();
        //     this.$refs.roomResveDetailGrid.updateCell(rowIndex, columnName, false);
        //   } else {
        //     const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();
        //
        //     records.forEach((record, idx) => {
        //       if (record._rid !== rowData._rid) {
        //         this.$refs.roomResveDetailGrid.updateCell(idx, columnName, false);
        //       }
        //     });
        //   }
        // } else {
        //   await this.$nextTick();
        //   this.$refs.roomResveDetailGrid.saveCell();
        //   this.$refs.roomResveDetailGrid.updateCell(rowIndex, columnName, true);
        // }
      }
    },
    async onGolfPackageLinkButtonClicked() {
      if (!this.roomReservationDetail.reservationInfo.rresveNo) {
        this.errorToast("저장후 사용 가능합니다");
        return;
      }

      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      const records = this.$refs.roomResveDetailGrid.getBatchCurrentViewRecords();
      const fromDate = _minBy(records.map(item => item.arrivalDate));
      const toDate = _maxBy(records.map(item => item.departureDate));
      this.isGolfPackageLinkPopup = true;
      this.$nextTick(() => {
        this.$refs.golfPackageLinkPopup.showPopup({
          fromDate,
          toDate,
          rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
        });
      });
    },
    onAvailableRoomPopupClosed() {
      this.isAvailableRoomPopup = false;
    },
    onAvailableRoomPopupConfirm(data) {
      if (this.isRoomResveDetailViewOpened) {
        this.onRoomDetailAddButtonClicked(null,{
          arrivalDate: data.bsnDate,
          roomType: data.roomType,
        });
      } else {
        this.onAddButtonClicked(null, {
          arrivalDate: data.bsnDate,
          roomType: data.roomType,
        });
      }
    },
    async onRoomRateDetailsByDatePopupClosed(args) {
      this.isRoomRateDetailsByDatePopup = false;

      if (args.isSaved) {
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    async onRoomReservationCancelPopupClosed(args) {
      this.isRoomReservationCancelPopup = false;

      await this.viewButtonClicked();
      await this.getRoomReservationDetail(args.rresveNo);
    },
    async onGolfPackageLinkPopupClosed(isSave, isDelete) {
      this.isGolfPackageLinkPopup = false;

      if (isSave || isDelete) {
        await this.fetchRoomReservations();
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    onVacantRoomPopupClosed(rowIndex, data) {
      this.isVacantRoomPopup = false;

      if (data) {
        this.$refs.roomResveDetailGrid.updateCell(rowIndex, "roomType", data.roomType);
        this.$refs.roomResveDetailGrid.updateCell(rowIndex, "roomNo", data.roomNo);
        this.$refs.roomResveDetailGrid.updateCell(rowIndex, "roomId", data.roomId);
      }
    },
    async onGolfPackageLinkClicked() {
      if (this.golfPackageLink) {
        if (!this.roomReservationDetail.reservationInfo.rresveNo) {
          if (!(await this.confirm("저장되지 않은 골프연결 내용을 삭제하시겠습니까?"))) {
            return;
          }
          this.roomReservationDetail.reservationInfo.trResveGroupMaps = [];
          this.roomReservationDetail.reservationInfo.resveNo = null;
          return;
        }
        if (this.isReservationDetailChanged()) {
          if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
            return;
          }
        }

        this.isUnlinkPackagePopup = true;

        this.$nextTick(() => {
          this.$refs.unlinkPackagePopup.showPopup({
            rresveNo: this.roomReservationDetail.reservationInfo.rresveNo,
          });
        });
      }
    },
    async onUnlinkPackagePopupClosed(isDelete) {
      this.isUnlinkPackagePopup = false;

      if (isDelete) {
        await this.fetchRoomReservations();
        await this.getRoomReservationDetail(this.roomReservationDetail.reservationInfo.rresveNo);
      }
    },
    onReservationSmsSendPopupClosed() {
      this.isReservationSmsSendPopup = false;
    },
    async onRoomResveWaitButtonClicked() {
      if (this.isReservationDetailChanged()) {
        this.errorToast("수정된 데이터가 있습니다. 저장후 진행해주시기 바랍니다");
        return;
      }

      const grid = this.$refs.roomResveDetailGrid;
      const selectedRecords = grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("선택된 객실 예약 상세 정보가 없습니다");
        return;
      }

      const selectedRecord = selectedRecords[0];

      if (!selectedRecord.stayId) {
        this.errorToast("저장되지 않은 객실 예약 상세 정보입니다. 저장후 진행해주시기 바랍니다");
        return;
      }

      if (selectedRecord.rresveStatus !== "RESVE") {
        this.errorToast("[" + commonCodesGetComName("RRESVE_STATUS", selectedRecord.rresveStatus) + "] 상태는 예약대기 할 수 없습니다");
        return;
      }

      if (!(await this.confirm("예약대기 하시겠습니까?"))) {
        return;
      }

      const resveStays = [
        {
          stayId: selectedRecord.stayId,
          rresveStatus: "WAIT",
        }
      ];

      await GolfErpAPI.roomReservationDetailSave({
        resveStays
      });

      this.infoToast("예약대기 상태로 변경되었습니다");

      await this.fetchRoomReservations();
      await this.getRoomReservationDetail(selectedRecord.rresveNo);
    },
    async onRoomResveConfirmButtonClicked() {
      if (this.isReservationDetailChanged()) {
        this.errorToast("수정된 데이터가 있습니다. 저장후 진행해주시기 바랍니다");
        return;
      }

      const grid = this.$refs.roomResveDetailGrid;
      const selectedRecords = grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("선택된 객실 예약 상세 정보가 없습니다");
        return;
      }

      const selectedRecord = selectedRecords[0];

      if (!selectedRecord.stayId) {
        this.errorToast("저장되지 않은 객실 예약 상세 정보입니다. 저장후 진행해주시기 바랍니다");
        return;
      }

      if (!["WAIT","CANCEL"].includes(selectedRecord.rresveStatus)) {
        this.errorToast("[" + commonCodesGetComName("RRESVE_STATUS", selectedRecord.rresveStatus) + "] 상태는 예약확정 할 수 없습니다");
        return;
      }

      if (!(await this.confirm("예약확정 하시겠습니까?"))) {
        return;
      }

      const resveStays = [
        {
          stayId: selectedRecord.stayId,
          rresveStatus: "RESVE",
        }
      ];

      await GolfErpAPI.roomReservationDetailSave({
        resveStays
      });

      this.infoToast("예약 상태로 변경되었습니다");

      await this.fetchRoomReservations();
      await this.getRoomReservationDetail(selectedRecord.rresveNo);
    },
    memoConfirm(args) {
      const {
        stayId,
        newMemoFlag,
        memoViews,
      } = args;

      if (stayId) {
        const filterData = this.roomReservations.filter(item => item.stayId === stayId);

        if (filterData.length > 0) {
          if (!(filterData[0].memoAllCount > 0)) {
            filterData[0].memoAllCount = memoViews.length;
          }
          filterData[0].isNewMemo = newMemoFlag || false;
        }

        const filterDataByDetail = this.roomReservationDetail.reservationDetails.filter(item => item.stayId === stayId);

        if (filterDataByDetail.length > 0) {
          if (!(filterDataByDetail[0].memoAllCount > 0)) {
            filterDataByDetail[0].memoAllCount = memoViews.length;
          }
          filterDataByDetail[0].isNewMemo = newMemoFlag || false;
        }
      }
    },
    onRoomResveDetailGridClick(e) {
      if (e.srcElement) {
        const columnName = e.srcElement.getAttribute('column-name');
        if (columnName) {
          switch (columnName) {
            case "roomSaleKind":
            case "roomType":
              this.gridEjsDropdownlistEditTemplateEventBus.$emit(
                'click-open',
                columnName,
              );
              break;
          }
        }
      }
    },
    onVisitEjsDropdownListEditTemplateChanged(args) {
      const {
        columnName,
        rowData,
        value,
        rowIndex,
      } = args;

      const grid = this.$refs.roomResveDetailGrid;

      if (columnName === "roomType") {
        this.checkAvailableRooms(value, rowData.arrivalDate, rowData.departureDate, rowData.stayId, grid, rowData._rid);

        const adultCnt = value ? commonCodesGetCommonCodeAttrbByCodeAndIdx("ROOM_TYPE", value, 1) || 0 : 0;
        grid.updateCell(rowIndex, "adultCnt", adultCnt);
      } else if (columnName === "roomSaleKind") {
        let roomAmt = this.roomReservationDetail.reservationDetails[rowIndex].roomAmt;
        if (value === "COMP") {
          roomAmt = 0;
        } else {
          roomAmt = this.roomReservationDetail.reservationDetails[rowIndex].roomAmt;
        }
        grid.updateCell(rowIndex,"roomAmt", roomAmt);
      }
    },
    async checkAvailableRooms(roomType, fromDate, toDate, stayId, grid, _rid) {
      if (!roomType) {
        return;
      }

      const { addedRecords } = grid ? grid.getBatchChanges() : { addedRecords: [] };

      if (_rid) {
        const findIndex = addedRecords.findIndex(item => item._rid === _rid);
        if (findIndex >= 0) {
          addedRecords.splice(findIndex, 1);
        }
      }

      await GolfErpAPI.checkAvailableRooms({
        fromDate: fromDate,
        toDate: toDate,
        roomType: roomType,
        addTrResveStays: addedRecords,
        stayId: stayId,
      });
    },
    onRoomReservationDailyStatusHeaderCellInfo(args) {
      const {
        node,
      } = args;

      node.style.paddingLeft = "2px";
      node.style.paddingRight = "2px";
    },
    onRoomReservationDailyStatusQueryCellInfo(args) {
      const {
        column: {
          field,
        },
        cell,
        data,
      } = args;

      if (field === "dwCode") {
        if (data.roomBsnCode === "CLOSE") {
          cell.style.color = commonCodesGetColorValue("BSN_CODE", data.roomBsnCode);
        } else {
          cell.style.color = commonCodesGetColorValue("DW_CODE", data.dwCode);
          if (data.hldyFlag) {
            cell.style.color = commonCodesGetColorValue("DW_CODE", "1");
          }
        }
      }

      if (["bsnDateByMmDd", "dwCode"].includes(field) && data.roomBsnCode === "CLOSE") {
        cell.style.textDecoration = "line-through";
      }

      if (
        field &&
        (field.startsWith("useRoomCountBy") ||
          field.startsWith("remainRoomCountBy"))
      ) {
        if (data[field] === 0) {
          cell.innerText = "-";
        }
      }
    },
    async onRoomReservationDailyStatusRowSelected(args) {
      const {
        data: {
          bsnDate
        },
      } = args;

      if (this.roomReservationDetail.reservationInfo.rresveNo) {
        if (this.isReservationDetailChanged()) {
          if (
            !this.isQuestionReservationDetailModify &&
            !(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))
          ) {
            this.changeStayDateToPreviousStayDate();
            return;
          }
          this.isQuestionReservationDetailModify = false;
        }

        if (
          !this.isRoomReservationSaveButtonClicked &&
          !this.isPreResveDateReturnFlag
        ) {
          this.onRoomReservationDetailViewClosed(false);
        } else {
          this.isRoomReservationSaveButtonClicked = false;
        }
      }

      this.searchOptions.stayDate = bsnDate;
      this.searchOptions.preStayDate = bsnDate;

      if (!this.isPreResveDateReturnFlag) {
        await this.fetchRoomReservations();
      } else {
        this.isPreResveDateReturnFlag = false;
      }

      this.isReservationDailyStatusGridAutoSelectRow = true;
    },
    onRoomReservationDailyStatusActionComplete(args) {
      const {
        rows,
      } = args;

      if (rows.length > 0 && this.isReservationDailyStatusGridAutoSelectRow) {
        this.$refs.roomReservationDailyStatus.selectRow(0);
      } else if (this.searchOptions.stayDate && !this.isReservationDailyStatusGridAutoSelectRow) {
        const rowIndex = this.$refs.roomReservationDailyStatus.getRowIndexByPrimaryKey(this.searchOptions.stayDate);

        this.$refs.roomReservationDailyStatus.selectRow(rowIndex);
      }
    },
    changeStayDateToPreviousStayDate() {
      this.searchOptions.stayDate = JSON.parse(JSON.stringify(this.searchOptions.preStayDate));
      this.isPreResveDateReturnFlag = true;

      const rowIndex = this.$refs.roomReservationDailyStatus.getRowIndexByPrimaryKey(this.searchOptions.stayDate);

      this.isQuestionReservationDetailModify = true;

      this.$refs.roomReservationDailyStatus.selectRow(rowIndex);
    },
    async roomAddSettingBygolfResveNo(resveNo, roomType, bsnDate) {
      if (resveNo) {
        const data = await GolfErpAPI.fetchGolfResveMember(resveNo);

        await this.onAddButtonClicked(null, {
          arrivalDate: bsnDate,
          roomType: roomType,
        });

        await this.$nextTick();

        this.roomReservationDetail.reservationInfo.groupName = data.resveName;
        this.roomReservationDetail.reservationInfo.grpNo = data.grpNo;
        this.roomReservationDetail.reservationInfo.grpName = data.grpName;
        this.roomReservationDetail.reservationInfo.grpKind = data.grpKind;
        this.roomReservationDetail.reservationInfo.resveName = data.resveName;
        this.roomReservationDetail.reservationInfo.membershipId = data.membershipId;
        this.roomReservationDetail.reservationInfo.memberNo = data.memberNo;
        this.roomReservationDetail.reservationInfo.memberDiv = data.memberDiv;
        this.roomReservationDetail.reservationInfo.memberGrade = data.memberGrade;
        this.roomReservationDetail.reservationInfo.contactName = data.contactName;
        this.roomReservationDetail.reservationInfo.contactTel = data.contactTel;
        this.roomReservationDetail.reservationInfo.resveKind = data.resveKind;
        this.roomReservationDetail.reservationInfo.resveChannel = data.resveChannel;
        this.roomReservationDetail.reservationInfo.areaCode = data.areaCode;
        this.roomReservationDetail.reservationInfo.sexCode = data.sexCode;
        this.roomReservationDetail.reservationInfo.vipFlag = data.vipFlag;
        this.roomReservationDetail.reservationInfo.resveRemarks = data.resveRemarks;

        if ((data.trResveGroupMaps || []).length > 0) {
          this.errorToast("이미 다른 객실 예약 정보에 연결된 골프 예약 정보입니다. 골프연결 항목이 삭제되었습니다");
          return;
        }

        this.roomReservationDetail.reservationInfo.resveNo = resveNo;
        this.roomReservationDetail.reservationInfo.trResveGroupMaps = [
          {
            tgResveConfirms: data.tgResveConfirms,
          }
        ];
      }
    },
    async searchReservationButtonClicked() {
      if (this.isReservationDetailChanged()) {
        if (!(await this.confirm("수정된 데이터가 있습니다. 진행하시겠습니까?"))) {
          return;
        }

        await this.onRoomReservationDetailViewClosed(false);
      }

      this.isRoomReservationSearchPopup = true;

      this.$nextTick(() => {
        this.$refs.roomReservationSearchPopup.showPopup({
          bsnDate: this.searchOptions.bsnDate,
        });
      });
    },
    onRoomReservationSearchPopupClosed() {
      this.isRoomReservationSearchPopup = false;
    },
    onRoomReservationSearchPopupConfirm(record) {
      const {
        arrivalDate,
        stayId
      } = record;

      const rowIndex = this.reservationDailyStatus.findIndex(item => item.bsnDate === arrivalDate);

      if (rowIndex >= 0) {
        this.findStayId = stayId;
        this.findRowData = record;

        this.$refs.roomReservationDailyStatus.selectRow(rowIndex);
      } else {
        this.searchOptions.bsnDate = arrivalDate;
        this.findStayId = stayId;
        this.findRowData = record;

        this.viewButtonClicked();
      }
    },
    // async setReservationDefaultInfo() {
    //   if (this.roomReservationDetail.reservationInfo.resveKind === null) {
    //     // if (this.reservationDetailInfo.reservationTimeLists[0].resveDate === await getTodayNavigationDate("YYYY-MM-DD")) {
    //     //   this.reservationDetailInfo.reservationInfo.resveKind = "DAY";
    //     // } else {
    //       this.roomReservationDetail.reservationInfo.resveKind = commonCodeGetComCodeDefaultValue(
    //         "RESVE_KIND"
    //       );
    //     // }
    //   }
    //   if (this.roomReservationDetail.reservationInfo.resveChannel === null) {
    //     this.roomReservationDetail.reservationInfo.resveChannel = commonCodeGetComCodeDefaultValue(
    //       "RESVE_CHANNEL"
    //     );
    //   }
    // },
  },
};
</script>
